import React from "react";
import img1 from "../../assests/images/About_us/img1.png";
import img2 from "../../assests/images/About_us/img2.png";
import img3 from "../../assests/images/About_us/img3.png";
import img4 from "../../assests/images/About_us/img4.png";
import service_img1 from "../../assests/images/About_us/loop1.webp";
import service_img2 from "../../assests/images/About_us/loop2.webp";
import service_img3 from "../../assests/images/About_us/loop3.webp";
import service_img4 from "../../assests/images/About_us/loop4.png";
import { useSelector } from "react-redux";

const WhatWeAreAbout = () => {
	const { aboutData } = useSelector((state) => state.Home);
	let abouUs_Services = [
		{
			heading: "We do For Student to get higher education",
			image: service_img1,
		},
		{
			heading: "We  Eliminate hunger To spread Happiness",
			image: service_img2,
		},
		{
			heading: "We do Good to make the world cleaner",
			image: service_img3,
		},
		{
			heading: "We do Good to   Clean Water and Sanitation",
			image: service_img4,
		},
	];

	let arr_Image = [
		{
			image: img1,
			name: "Byron Hopkins",
		},
		{
			image: img2,
			name: "Nathan Banks",
		},
		{
			image: img3,
			name: "Blanche Moody",
		},
		{
			image: img4,
			name: "Ora Vargas",
		},
	];

	return (
		<div className="WhatWeAreAbout">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 text-right">
						<div data-aos="fade-up" className="what_we_div">
							<p className="main_heading">
								What we are <br />
								about
							</p>
						</div>
					</div>
					<div className="col-lg-6 text-center">
						<div className="div_content">
							{/* <p data-aos="fade-left" className="text mt-0">
								Enim praesent elementum facilisis leo vel fringilla est
								ullamcorper eget. Parturient montes nascetur ridiculus mus
								mauris. Id aliquet lectus proin nibh nisl condimentum id.
								Sagittis id consectetur purus ut faucibus pulvinar elementum.
							</p>
							<p data-aos="fade-right" className="text">
								Id aliquet lectus proin nibh nisl condimentum id. Sagittis id
								consectetur purus ut faucibus pulvinar elementum. Massa
								tincidunt nunc pulvinar sapien et. Sed sed risus pretium quam
								vulputate dignissim suspendisse in. Ultricies leo integer
								malesuada nunc vel risus
							</p>
							<p data-aos="fade-left" className="head">
								Strategy
							</p>
							<p data-aos="fade-right" className="text">
								Id aliquet lectus proin nibh nisl condimentum id. Sagittis id
								consectetur purus ut faucibus pulvinar elementum. Massa
								tincidunt nunc pulvinar sapien et. Sed sed risus pretium quam
								vulputate dignissim suspendisse in. Ultricies leo integer
								malesuada nunc vel risus
							</p>
							<p data-aos="fade-left" className="head">
								Branding
							</p>
							<p data-aos="fade-right" className="text">
								Id aliquet lectus proin nibh nisl condimentum id. Sagittis id
								consectetur purus ut faucibus pulvinar elementum. Massa
								tincidunt nunc pulvinar sapien et. Sed sed risus pretium quam
								vulputate dignissim suspendisse in. Ultricies leo integer
								malesuada nunc vel risus
							</p>
							<p data-aos="fade-left" className="head">
								E commerce
							</p>
							<p data-aos="fade-right" className="text">
								{" "}
								Id aliquet lectus proin nibh nisl condimentum id. Sagittis id
								consectetur purus ut faucibus pulvinar elementum. Massa
								tincidunt nunc pulvinar sapien et. Sed sed risus pretium quam
								vulputate dignissim suspendisse in. Ultricies leo integer
								malesuada nunc vel risus
							</p> */}

							<p
								className="blog-des"
								dangerouslySetInnerHTML={{
									__html: aboutData?.data?.about?.description,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-3"></div>
					<div className="col-lg-4 text-center">
						<p data-aos="fade-down" className="people_head">
							The people <br />
							that make it happen
						</p>
					</div>
					<div className="col-lg-5 text-center">
						<div className="row">
							{aboutData?.data?.team?.data.map((item, index) => {
								return (
									<div data-aos="flip-down" key={index} className="col-lg-6">
										<img
											className="item_img"
											src={`${item?.image_url}${item?.image}`}
										/>
										<p className="item_name mt-3 mb-4">{item?.title}</p>
									</div>
								);
							})}
						</div>
					</div>
					<div className="col-lg-12 text-center">
						<p data-aos="fade-left" className="we_Are_company">
							We are a company that{" "}
						</p>
						<p data-aos="fade-right" className="does_good">
							DOES GOOD
						</p>
					</div>
				</div>
				{aboutData?.data?.company?.data.map((item, index) => {
					return (
						<div key={index} className="row mb-5 pb-5">
							<div className="col-lg-3"></div>
							<div className="col-lg-5 text-right">
								<p data-aos="fade-down" className="content_heading">
									{item.title}
								</p>
							</div>
							<div className="col-lg-4 text-right">
								<img
									data-aos="flip-up"
									className="w-100"
									src={`${item.image_url}${item.image}`}
									alt="About 1"
								/>
							</div>
						</div>
					);
				})}

				<div className="row mb-our_impact">
					<div className="col-lg-2">
						<p data-aos="fade-left" className="ourtxt_head">
							Our impact{" "}
						</p>
						<p data-aos="fade-right" className="ourtxt_txt">
							Id aliquet lectus proin nibh nisl conum. Sagittis id conctetur
							purus ut faucibus pulvinar elementum. Lorem ipsum dolor sit amet,
							consectetur ex ea commodo consequat.
						</p>
					</div>
					<div className="col-lg-10">
						<div className="row">
							{aboutData?.data?.impact?.data?.map((item, index) => {
								return (
									<div className="col-md-6" key={index}>
										<div data-aos="flip-down" className="gry_simpleBox mt-0">
											<>
												<p className="gry_simpleBox_head">{item?.title}</p>
												<p className="gry_simpleBox_txt">{item?.description}</p>
											</>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					{/* <div className="col-lg-5">
						<div data-aos="flip-down" className="gry_simpleBox mt-0">
							<p className="gry_simpleBox_head">
								Egestas quis ipsum suspendisseultrices gravida
							</p>
							<p className="gry_simpleBox_txt">
								Luctus accumsan tortor posuere ac ut consequat semper viverra
								nam. Ac auctor augue mauris augue neque. Amet commodo nulla
								facilisi nullam vehicula ipsum a arcu. Libero nunc consequat
								interdum varius sit amet mattis. Egestas quis ipsum Suspendisse.
							</p>
						</div>
						<div data-aos="flip-down" className="gry_simpleBox">
							<p className="gry_simpleBox_head">
								Egestas quis ipsum suspendisseultrices gravida
							</p>
							<p className="gry_simpleBox_txt">
								Luctus accumsan tortor posuere ac ut consequat semper viverra
								nam. Ac auctor augue mauris augue neque. Amet commodo nulla
								facilisi nullam vehicula ipsum a arcu. Libero nunc consequat
								interdum varius sit amet mattis. Egestas quis ipsum Suspendisse.
							</p>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default WhatWeAreAbout;
