import React, { useEffect, useState } from "react";
import AOS from "aos";
import Header from "../../Components/Header/Header";
import "../../assests/css/Community&Reciveres.css";
import first_img from "../../assests/images/Review_Community/rec1.png";
import second_img from "../../assests/images/Review_Community/rec2.png";
import $ from "jquery";
import { useSelector } from "react-redux";

const Reciveres = () => {
	const [trans, settrans] = useState(200);
	const [trans2, settrans2] = useState(0);
	const [sliderimg, setsliderimg] = useState(second_img);
	const [sliderimg2, setsliderimg2] = useState(first_img);
	const { homeData } = useSelector((state) => state.Home);
	console.log(homeData);
	useEffect(() => {
		// setTimeout(() => {
		//     AOS.init();
		// }, 1000);

		$(window).scroll(function () {
			var scroll = $(document).scrollTop();
			console.log(scroll);
			if (scroll > 6400 && scroll < 6800) {
				// let maxScroll = 6800;
				let maxScroll = window.innerHeight * 100;
				let newS = maxScroll - scroll;
				let percentage = (newS / maxScroll) * 100;
				let newTranslate = 100 * (percentage / 100);
				settrans(newTranslate);
				settrans2(newTranslate);
				setsliderimg(first_img);
				setsliderimg2(second_img);
			} else {
				settrans(0);
				setsliderimg(second_img);
				settrans2(150);
				setsliderimg2(first_img);
			}
			if (scroll > 6700) {
				$(".po").addClass("darkHeader");
				console.log("Approch" + scroll);
			} else {
				$(".po").removeClass("darkHeader");
			}
		});
	}, []);
	let Image = homeData?.data?.receiver?.image_url;
	return (
		<div className="Community po">
			{/* <Header black={true} /> */}
			<div className="Community_content">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="slider-img-wrapper">
								<div className="row">
									<div className="col-lg-6">
										<img
											className="img-fluid slide_img-1"
											src={`${Image}${homeData?.data?.receiver?.image}`}
											style={{ transform: `translateY(${trans2}px)` }}
										/>
										{/* <img className="img-fluid scroll-1-img" src={approach_2} /> */}
									</div>
									<div className="col-lg-6">
										<img
											className="img-fluid slide_img-2"
											src={`${Image}${homeData?.data?.receiver?.image2}`}
											style={{ transform: `translateY(${trans}px)` }}
										/>
										{/* <img className="img-fluid scroll-2-img" src={approach_1} /> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 pr-5 ">
							<p className="heading mb-0">Reciveres</p>
							<div className="brdr-btm-line mb-3"></div>
							{/* <p className="text">
								Luctus accumsan tortor posuere ac ut consequat semper viverra
								nam. Ac auctor augue mauris augue neque. Amet commodo nulla
								facilisi nullam vehicula ipsum a arcu.
							</p>
							<p className="text ">
								Amet commodo nulla facilisi nullam vehicula ipsum a arcu.
							</p>
							<ul className="ul_list_txt pos-rel">
								<li className="text mb-3">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</li>
								<li className="text mb-3">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</li>
								<li className="text mb-3">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</li>
								<li className="text mb-3">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor
								</li>
							</ul> */}
							<p
								className="blog-des"
								dangerouslySetInnerHTML={{
									__html: homeData?.data?.receiver?.description,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reciveres;
