import React from "react";
import '../../assests/css/JoinCommunity.css';
import facebook_ico from '../../assests/images/Join_community/facebook.png';
import twitter_ico from '../../assests/images/Join_community/twitter.png';
import whatsapp_ico from '../../assests/images/Join_community/whatsapp.png';
import skype_ico from '../../assests/images/Join_community/skype.png';
import ajebsa_ico from '../../assests/images/Join_community/ajebsa.png';
import youtube_ico from '../../assests/images/Join_community/youtube.png';

const JoinCommunity = () => {
    return (
        <div className="JoinCommunity">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <p className="text_heading">Join Our Community to bring <br />
                            change to the world</p>
                        <p className="text_p mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquipo consequat. </p>
                        <div className="icon_main_div">
                            <div className="icon_div">
                                <img src={facebook_ico} />
                            </div>
                            <div className="icon_div">
                                <img src={twitter_ico} />
                            </div>
                            <div className="icon_div">
                                <img src={whatsapp_ico} />
                            </div>
                            <div className="icon_div">
                                <img src={skype_ico} />
                            </div>
                            <div className="icon_div">
                                <img src={ajebsa_ico} />
                            </div>
                            <div className="icon_div">
                                <img src={youtube_ico} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinCommunity;