import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const SaveNatureApi = createAsyncThunk(
	"save-nature/detail",
	async () => {
		try {
			const { data } = await axios.get(`${URL}save-nature/get-save-nature`);
			return data;
		} catch (err) {
			return err.message;
		}
	},
);
