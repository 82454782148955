import React, { useRef } from 'react';
import '../../assests/css/videoBgPlayPause.css';
import home_firstvideo from '../../assests/video/intro_earth.mp4';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';

const VideoBgPausePlay = () => {
    let navigate = useNavigate();

    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        if (!vidRef.current.paused) {
            vidRef.current.pause();
        } else {
            vidRef.current.play();
        }
    }
    return (
        <div className='videoBgPlayPause'>
            <div className='videoBgPlayPause_content '>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-lg-7 pos-rel'>
                            <div>
                                <video ref={vidRef} id="video_bgplaypause" className='' autoPlay loop muted>
                                    <source src={home_firstvideo} type='video/mp4' />
                                </video>
                                <div
                                    onClick={handlePlayVideo}
                                    className='bg-trans-video'>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='rotate_earth_div'>
                                <p className='heading mb-0 lh-01'>We Are Trying to</p>
                                <p className='heading'>Create better Future</p>
                                <div className='brdr-btm-wht mb-3'>

                                </div>
                                <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                <p onClick={() => { navigate("/savenature") }} className='text-white pointer_cursor mt-4'>Learn More <ArrowRightAltIcon className=' ml-3' /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default VideoBgPausePlay;