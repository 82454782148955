import React, { useEffect } from "react";
import AOS from "aos";
import "../../assests/css/HowStarted.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import howstarted_top from "../../assests/images/how_started/howstarted_top.webp";
import howstarted_one from "../../assests/images/how_started/howstarted_one.webp";
import howstarted_two from "../../assests/images/how_started/howstarted_two.png";
import howstarted_whale from "../../assests/images/how_started/howstarted_whale.webp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import $ from "jquery";

const HowStarted = () => {
  useEffect(() => {
    $(window).on("load resize scroll", function () {
      $(".img_box").each(function () {
        var windowTop = $(window).scrollTop();
        var elementTop = $(this).offset().top;
        var leftPosition = windowTop + elementTop;
        $(this).find(".backDv").css({ height: leftPosition });
      });
    });
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, []);
  $(window)
    .scroll(function () {
      // selectors
      var $window = $(window),
        $body = $("body"),
        $panel = $(".parallax_section");

      // Change 33% earlier than scroll position so colour is there when you arrive.
      var scroll = $window.scrollTop() + $window.height() / 5;

      $(".parallax_section").removeClass("active");

      $panel.each(function () {
        var $this = $(this);

        // if position is within range of this panel.
        // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
        // Remember we set the scroll to 33% earlier in scroll var.
        if (
          $this.position().top <= scroll &&
          $this.position().top + $this.height() > scroll
        ) {
          $this.addClass("active");
        }
      });
    })
    .scroll();
  return (
    <div className="HowStarted">
      <Header black={true} />
      <div className="HowStarted_content">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 mb-5">
              <div className="content_top_div">
                <p className="top_heading">Life below water</p>
                <p className="top_heading_main">
                  How Benefacto Started heling people for Good
                </p>
                <p className="text_main">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor.
                </p>
                <p
                  onClick={() => {
                    navigate("/");
                  }}
                  className="clr_grn pointer_cursor mt-5"
                >
                  Read More <ArrowRightAltIcon className=" ml-3" />
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-5">
              <div className="img_box">
                <img className="w-100" src={howstarted_top} alt="how started" />
                <div className="backDv"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-5">
              <p className="mb-5 txt_above_txt">
                At auctor urna nunc id cursus metus aliquam eleifend mi.
                Placerat orci nulla pellentesque dignissim enim. Libero enim sed
                faucibus turpis in eu mi bibendum neque. Fringilla est
                ullamcorper eget nulla facilisi etiam dignissim. Mauris sit amet
                massa vitae tortor condimentum lacinia. Eget mauris pharetra et
                ultrices neque ornare aenean. Amet aliquam id diam maecenas
                ultricies mi eget mauris pharetra. Turpis in eu mi bibendum.
              </p>
            </div>
          </div>
          <div className="parallax_images">
            <div className="row parallax_section">
              <div className="col-lg-6 align-center">
                <div className="section__body">
                  <div className="section__text">
                    <p className="mb-4 img_text">
                      Odio aenean sed adipiscing diam donec adipiscing tristique
                      risus. Pellentesque habitant morbi tristique senectus et
                      netus et malesuada fames. Nulla malesuada pellentesque
                      elit eget gravida. Varius morbi enim nunc faucibus a
                      pellentesque sit amet. A diam maecenas sed enim ut sem
                      viverra. Sagittis purus sit amet volutpat. Pulvinar neque
                      laoreet suspendisse interdum consectetur libero id. Ut sem
                      nulla pharetra diam sit amet nisl suscipit. At auctor urna
                      nunc id cursus metus aliquam eleifend mi. Placerat orci
                      nulla pellentesque dignissim enim. Libero enim sed
                      faucibus turpis in eu mi bibendum neque. Fringilla est
                      ullamcorper eget nulla facilisi etiam dignissim. Mauris
                      sit amet massa vitae tortor condimentum lacinia. Eget
                      mauris pharetra et ultrices neque ornare aenean. Amet
                      aliquam id diam maecenas ultricies mi eget mauris
                      pharetra. Turpis in eu mi bibendum.
                    </p>
                    <p className="mb-0 img_text">
                      Consequat mauris nunc congue nisi vitae suscipit tellus
                      mauris. Id venenatis a condimentum vitae sapien
                      pellentesque. Feugiat sed lectus vestibulum mattis
                      ullamcorper velit sed ullamcorper. Sit amet justo donec
                      enim diam vulputate ut. Quis commodo odio aenean sed
                      adipiscing diam donec adipiscing tristique. Eget magna
                      fermentum iaculis eu non diam phasellus vestibulum lorem.
                      Rhoncus est pellentesque elit ullamcorper. Porttitor massa
                      id neque aliquam vestibulum. Condimentum id venenatis a
                      condimentum vitae sapien. Viverra aliquet eget sit amet
                      tellus cras adipiscing. Amet dictum sit amet justo donec
                      enim. Fringilla est ullamcorper eget nulla facilisi.
                      Elementum integer enim neque volutpat. Egestas diam in
                      arcu cursus euismod. Tincidunt eget nullam non nisi est
                      sit amet. Elementum nibh tellus molestie nunc.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="section__img">
                  <div
                    className="section__img__inner"
                    style={{ backgroundImage: `url(${howstarted_one})` }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="row parallax_section">
              <div className="col-lg-6 align-center">
                <div className="section__body">
                  <div className="section__text">
                    <p className="mb-4 img_text">
                      Viverra aliquet eget sit amet tellus cras adipiscing. Amet
                      dictum sit amet justo donec enim. Fringilla est
                      ullamcorper eget nulla facilisi. Elementum integer enim
                      neque volutpat. Egestas diam in arcu cursus euismod.
                      Tincidunt eget nullam non nisi est sit amet. Elementum
                      nibh tellus molestie nunc.
                    </p>
                    <p className="mb-5 img_text">
                      Felis eget velit aliquet sagittis id consectetur. Mi
                      tempus imperdiet nulla malesuada pellentesque elit.
                      Ullamcorper velit sed ullamcorper morbi tincidunt ornare
                      massa eget. Volutpat consequat mauris nunc congue nisi
                      vitae suscipit. Tincidunt vitae semper quis lectus nulla
                      at volutpat. Mattis nunc sed blandit libero volutpat sed
                      cras ornare arcu. Lectus urna duis convallis convallis
                      tellus id interdum velit laoreet. In nisl nisi scelerisque
                      eu ultrices. Felis imperdiet proin fermentum leo vel orci.
                      Lorem mollis aliquam ut porttitor leo a diam. Mauris
                      commodo quis imperdiet massa tincidunt nunc pulvinar
                      sapien et. Sollicitudin tempor id eu nisl nunc mi ipsum
                      faucibus vitae
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="section__img">
                  <div className="backdiv"></div>
                  <div
                    className="section__img__inner"
                    style={{ backgroundImage: `url(${howstarted_two})` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="full_image">
            <div className="row">
              <div className="col-lg-12 mt-5">
                <img
                  src={howstarted_whale}
                  alt="nature image"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowStarted;
