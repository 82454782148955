import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';

const Footer_Four = () => {

    let navigate= useNavigate();

    return (
        <div className='footer_four'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 p-0'>
                        <div className='text_area'>
                            <p className='four_square_text'>Join Our mission</p>
                            <p className='four_square_text'>to help other</p>
                            <p className='four_square_text'>to do good</p>
                            <button onClick={()=>{navigate('/aboutus')}} className='btn btn-light bt-learn-more'>Learn More <ArrowRightAltIcon className=' right_icon_learn_more' /></button>
                        </div>
                    </div>
                    <div className='col-lg-7 p-0'>
                        <div className='row'>
                            <div className='col-lg-6 p-0'>
                                <div className='square_box_layout'>
                                    <p className='square_green_txt'>Givers</p>
                                    <br />
                                    <p className='square_green_txt'></p>
                                    <p onClick={()=>{navigate('/givers')}} className='lrn_mr'>Learn More <ArrowRightAltIcon className='ml-2' /></p>
                                </div>
                                <div className='square_box_layout'>
                                    <p className='square_green_txt'>Brand <br />
                                        Partnership</p>
                                    <p onClick={()=>{navigate('/faqs')}}  className='lrn_mr'>Learn More <ArrowRightAltIcon className='ml-2' /></p>
                                </div>
                            </div>
                            <div className='col-lg-6 p-0'>
                                <div className='square_box_layout'>
                                    <p className='square_green_txt'>Invest</p>
                                    <br />
                                    <p className='square_green_txt'></p>
                                    <p onClick={()=>{navigate('/legallink')}}  className='lrn_mr'>Learn More <ArrowRightAltIcon className='ml-2' /></p> 
                                </div>
                                <div className='square_box_layout'>
                                    <p className='square_green_txt'>Student <br />
                                        Volunters</p>
                                    <p onClick={()=>{navigate('/community')}}  className='lrn_mr'>Learn More <ArrowRightAltIcon className='ml-2' /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer_Four;