import { createSlice } from "@reduxjs/toolkit";
import { ReceiverApi } from "../action/ReceiverAction";

const ReceiverReducer = createSlice({
	name: "ReceiverApi",
	initialState: {
		receiverData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[ReceiverApi.pending]: (state) => {
			state.loading = true;
		},
		[ReceiverApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.receiverData = action.payload.response;
		},
		[ReceiverApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default ReceiverReducer.reducer;
