import React from "react";
import "../../assests/css/OurServices.css";
import service_1 from "../../assests/images/home/service_1.png";
import service_2 from "../../assests/images/home/service_2.png";
import service_3 from "../../assests/images/home/service_3.png";
import service_4 from "../../assests/images/home/service_4.png";
import { useSelector } from "react-redux";

const OurServices = () => {
	let ourServices = [
		{
			image: service_1,
			heading: "Our Service 01",
			desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
		},
		{
			image: service_2,
			heading: "Our Service 02",
			desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
		},
		{
			image: service_3,
			heading: "Our Service 03",
			desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
		},
		{
			image: service_4,
			heading: "Our Service 04",
			desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
		},
	];
	const { homeData } = useSelector((state) => state.Home);
	console.log(homeData);

	return (
		// <div className='OurServices'>
		//     <div className='service_container'>
		//         {ourServices.map((item, index) => {
		//             return (
		// <div key={index} style={{ backgroundImage: `url("${item.image}")` }} className='service_block'>
		//                     <p className='heading mb-5'>{item.heading}</p>
		//                     <p className='text'>{item.desc}</p>
		//                 </div>
		//             )
		//         })}
		//     </div>
		// </div>

		<section id="timeline">
			{homeData?.data?.services?.data.map((item, index) => {
				return (
					<div className="tl-item">
						<div
							key={index}
							className="tl-bg"
							style={{
								backgroundImage: `url("${item?.image_url}${item?.image}")`,
							}}
						></div>

						<div className="tl-year">
							<p className="f2 heading--sanSerif">
								{item?.created_at?.split("-")[0]}
							</p>
						</div>

						<div className="tl-content">
							<h1>{item?.title}</h1>
							<p>{item?.description}</p>
						</div>
					</div>
				);
			})}
		</section>
	);
};

export default OurServices;
