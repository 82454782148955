import React from "react";
import Header from "../Header/Header";
import home_firstvideo from "../../assests/video/intro_video.mp4";
import "../../assests/css/Video_First_Section.css";
import { useSelector } from "react-redux";

const Video_First_Section = (props) => {
	return (
		<div className="savenature_component pos-rel">
			<div className="first_component">
				<video id="video" className="videoTag" autoPlay loop muted>
					<source src={props.content.video} type="video/mp4" />
				</video>
				<Header />
				<div className="container">
					<div className="row">
						<div className="col-lg-7 pos-rel">
							<div className="left_home_div">
								<div
									style={{
										backgroundImage: `url("${props?.content?.bg_imgleft}")`,
									}}
									className="home_left_text"
								>
									<div className="pad-top-text">
										<p className="home_content_large">
											{props?.content?.heading_one}
										</p>
										<p className="home_content_large">
											{props?.content?.heading_two}
										</p>
										<p className="home_content_small mt-3">
											{props?.content?.desc}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5  pos-rel">
							<div
								style={{
									backgroundImage: `url("${props?.content?.bg_imgleft}")`,
								}}
								className="home_right_text"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Video_First_Section;
