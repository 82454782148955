import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const BlogApi = createAsyncThunk("blogs/detail", async (url) => {
	try {
		const { data } = await axios.get(
			`${URL}blogs/showBlog?page=${url ? url : 1}`,
		);
		return data;
	} catch (err) {
		return err.message;
	}
});

export const BlogCategoriesApi = createAsyncThunk(
	"blogscategories/detail",
	async (datas) => {
		try {
			const { data } = await axios.get(
				`${URL}blogs/get-blog?page=${
					datas.pages ? datas.pages : 1
				}&category_id=${datas.cateId ? datas.cateId : ""}`,
			);
			return data;
		} catch (err) {
			return err.message;
		}
	},
);
