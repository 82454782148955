import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useSelector } from "react-redux";

const LeftImageTop = (props) => {
	const { aboutData } = useSelector((state) => state.Home);
	const Image = aboutData?.data?.about?.image_url;
	console.log(aboutData);
	return (
		<div className="RightHalfImageTop LeftImageTop">
			<div className="flx-main">
				<div className="flx-item-left">
					<div
						style={{
							backgroundImage: `url("${Image}${aboutData?.data?.about?.image}")`,
						}}
						className="GetStartedTopSection mb-getStarted"
					></div>
				</div>
				<div data-aos="fade-left" className="flx-item-right">
					<div className="lft-pd">
						<div className="row ">
							<div className="col-lg-12">
								<h1 className="heading">{aboutData?.data?.about?.title}</h1>
								<p className="txt my-4">
									{aboutData?.data?.about?.meta_description}
								</p>
								{/* <button className="btn btn-light bt-get-started">
                                    Get Started <ArrowRightAltIcon className='ml-2' />
                                </button> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LeftImageTop;
