import { createSlice } from "@reduxjs/toolkit";
import { faqsActionApi } from "../action/faqsAction";

const FaqsReducer = createSlice({
	name: "faqsApi",
	initialState: {
		faqsData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[faqsActionApi.pending]: (state) => {
			state.loading = true;
		},
		[faqsActionApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.faqsData = action.payload.response;
		},
		[faqsActionApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default FaqsReducer.reducer;
