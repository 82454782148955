import React, { useEffect } from "react";
import AOS from "aos";
import "../../assests/css/LifeBelowWater.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import howstarted_top from "../../assests/images/how_started/howstarted_top.webp";
import content_img_group from "../../assests/images/life_below_water/content_img_group.webp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import AroundTheGlobeSlider from "../../Components/Sliders/AroundTheGlobeSlider";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";

const LifeBelowWater = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 1000);
  }, []);
  let navigate = useNavigate();

  let top_content = [
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  let bottom_content = [
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: content_img_group,
      catergory: "Space Sustainibility",
      heading:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  return (
    <div className=" LifeBelowWater">
      <Header black={true} />
      <div className="LifeBelowWater_content">
        <section className="top_section">
          <div className="container">
            <div className="row ">
              <div className="col-lg-12 ">
                <p className="top_heading">Life below water</p>
              </div>
              <div className="col-lg-6 my-5">
                <img className="w-100" src={howstarted_top} alt="how started" />
                <div className="text-center">
                  <p className="life_before_txt">Life below water</p>

                  <p className="top_heading_px">
                    How Benefacto Started heling people for Good
                  </p>
                  <p className="text_main">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor.
                  </p>
                  <p
                    onClick={() => {
                      navigate("/");
                    }}
                    className="clr_grn pointer_cursor mt-5"
                  >
                    Read More <ArrowRightAltIcon className=" ml-3" />
                  </p>
                </div>
              </div>
              <div className="col-lg-6 my-5">
                <div className="row blog-wrapper mb-5">
                  {top_content.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-6">
                        <div className="card-wrapper">
                          <div className="card-img">
                            <img
                              src={item.image}
                              className="img_grp_ppl"
                              alt="group people"
                            />
                          </div>
                          <div className="card-content-wrapper">
                            <p className="text-top-group">{item.catergory}</p>
                            <p className="text-top-group-heading">
                              {item.heading}
                            </p>
                            <p className="text-top-group-desc">{item.desc}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Around_the_Globe">
          <AroundTheGlobeSlider />
        </section>
        <section className="crds_categories_sec mb-auto-lifebelow">
          <div className="container">
            <div className="row">
              {bottom_content.map((item, index) => {
                return (
                  <div key={index} className="col-lg-3">
                    <img
                      src={item.image}
                      className="w-100"
                      alt="bottom content"
                    />
                    <p className="text-top-group mt-3">{item.catergory}</p>
                    <p className="text-top-group-heading">{item.heading}</p>
                    <p className="text-top-group-desc mt-2">{item.desc}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="JoinCommunity">
          <JoinCommunity />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default LifeBelowWater;
