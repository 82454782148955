import React, { useEffect } from "react";
import AOS from "aos";
import "../../assests/css/SaveNature.css";
import Video_First_Section from "../../Components/Nature Page/Video_First_Section";
import HelpToSave from "../../Components/Nature Page/HelpToSave";
import Cards_Section from "../../Components/Nature Page/Cards_Section";
import Footer from "../../Components/Footer/Footer";
import Footer_Four from "../../Components/Footer/Footer_Four";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import powerplant from "../../assests/images/custom_image/powerplant.webp";
import hands from "../../assests/images/custom_image/hands.webp";
import home_firstvideo from "../../assests/video/intro_space.mp4";
import SpaceSection from "../../Components/Nature Page/SpaceSection";
import { useDispatch, useSelector } from "react-redux";
import {
	SaveSpaceApi,
	SaveSpaceCateApi,
} from "../../store/action/SavespaceAction";

const SaveSpace = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(SaveSpaceApi());
		dispatch(SaveSpaceCateApi());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	let MultiContent_One = {
		image: powerplant,
		heading_one: "We making the change",
		heading_two: "For better Future",
	};
	let MultiContent_Two = {
		image: hands,
		heading_one: "We Motivate to do",
		heading_two: "Social Good",
	};
	let video_sec_content = {
		video: home_firstvideo,
		heading_one: "We do good for",
		heading_two: "Space Sustainibility",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
	};

	return (
		<div className="nature_page_one">
			<section className="first_section">
				<Video_First_Section content={video_sec_content} />
			</section>
			<section className="second_section">
				<HelpToSave />
			</section>
			<section className="third_section">
				<SpaceSection />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_One} />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_Two} />
			</section>
			<section className="Footer_Four_section">
				<Footer_Four />
			</section>
			<section className="last_section">
				<Footer />
			</section>
		</div>
	);
};

export default SaveSpace;
