import { createSlice } from "@reduxjs/toolkit";
import { BlogApi, BlogCategoriesApi } from "../action/BlogAction";

const BlogReducer = createSlice({
	name: "BlogApi",
	initialState: {
		blogsData: [],
		blogscategoriesData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[BlogApi.pending]: (state) => {
			state.loading = true;
		},
		[BlogApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.blogsData = action.payload.response;
		},
		[BlogApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[BlogCategoriesApi.pending]: (state) => {
			state.loading = true;
		},
		[BlogCategoriesApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.blogscategoriesData = action.payload.response;
		},
		[BlogCategoriesApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default BlogReducer.reducer;
