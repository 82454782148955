import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const TokensApi = createAsyncThunk("tokens/detail", async () => {
	try {
		const { data } = await axios.get(`${URL}tokens/get-tokens`);
		return data;
	} catch (err) {
		return err.message;
	}
});
