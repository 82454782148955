import { createSlice } from "@reduxjs/toolkit";
import { ContactApi } from "../action/contactAction";

const ContactReducer = createSlice({
	name: "contactApi",
	initialState: {
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[ContactApi.pending]: (state) => {
			state.loading = true;
		},
		[ContactApi.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[ContactApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default ContactReducer.reducer;
