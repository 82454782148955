import { configureStore } from "@reduxjs/toolkit";
import BlogReducer from "./reducers/BlogReducer";
import contactReducer from "./reducers/contactReducer";
import cryptotokenReducer from "./reducers/cryptotokenReducer";
import CummunityReducer from "./reducers/CummunityReducer";
import faqsReducer from "./reducers/faqsReducer";
import GiverReducer from "./reducers/GiverReducer";
import Homereducers from "./reducers/Homereducer";
import PartnerReducer from "./reducers/PartnerReducer";
import PolicyReducer from "./reducers/PolicyReducer";
import ReceiverReducer from "./reducers/ReceiverReducer";
import SaveNatureReducer from "./reducers/SaveNatureReducer";
import SaveSpaceReducer from "./reducers/SaveSpaceReducer";
import SiteSettingReducer from "./reducers/SiteSettingReducer";
import SubscribeReducer from "./reducers/SubscribeReducer";
import EarthReducer from "./reducers/EarthReduces";

const Store = configureStore({
	reducer: {
		Home: Homereducers,
		Blogs: BlogReducer,
		givers: GiverReducer,
		token: cryptotokenReducer,
		space: SaveSpaceReducer,
		faqs: faqsReducer,
		receiver: ReceiverReducer,
		partner: PartnerReducer,
		cummunity: CummunityReducer,
		saveNature: SaveNatureReducer,
		sitesetting: SiteSettingReducer,
		subscibe: SubscribeReducer,
		policy: PolicyReducer,
		contact: contactReducer,
		earth: EarthReducer,
	},
});
export default Store;
