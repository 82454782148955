import React from 'react';
import { article1_home, article2_home, article3_home, article4_home } from '../../constant/index';

const ReadOurArticle = () => {

    let read_arcticles = [
        {
            image: article1_home,
            category: "#Ecosystem",
            heading: "How to protect Ecosystem",
            text: "Gravida quis blandit turpis cursus in hac habitasse platea dictumst. Quisque sagittis purus sit amet volutpat consequat congue.",
        }, {
            image: article2_home,
            category: "#Business",
            heading: "Startup Tips",
            text: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam. ",
        }, {
            image: article3_home,
            category: "#Business",
            heading: "Startup Tips",
            text: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam. ",
        }, {
            image: article4_home,
            category: "#Business",
            heading: "Startup Tips",
            text: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam. ",
        }
    ]

    return (
        <div className='ReadOurArticle'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <p className='brdr_btm_heading'>Read Our Articles</p>
                        <p className='mt-4 be_notified'>BE NOTIFIED ABOUT <br /> EVERYTHING</p>
                    </div>
                </div>
            </div>
            <marquee className="marquee_text">
                <span className='dots'>.</span>
                WE CARE
                <span className='dots'>.</span>
                WE MOTIVATE
                <span className='dots'>.</span>
                WE CARE
                <span className='dots'>.</span>
                WE ASPIRE
                <span className='dots'>.</span>
                WE CARE
                <span className='dots'>.</span>
                WE MOTIVATE
                <span className='dots'>.</span>
                WE CARE
                <span className='dots'>.</span>
                WE MOTIVATE
                <span className='dots'>.</span>
                WE CARE
                <span className='dots'>.</span>
                WE ASPIRE
                <span className='dots'>.</span>
                WE CARE
                <span className='dots'>.</span>
                WE MOTIVATE
            </marquee>
            <div className='read_arcticles'>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 pos-rel'>
                            <div className='stickyy'>
                                <img className='w-100' src={read_arcticles[0].image} alt="article 1" />
                                <p className='category'>{read_arcticles[0].category}</p>
                                <p className='heading'>{read_arcticles[0].heading}</p>
                                <p className='text'>{read_arcticles[0].text}</p>
                            </div>
                        </div>
                        <div className='col-lg-6 '>
                            <div className='row'>
                                {read_arcticles.map((item, index) => {
                                    return (
                                        <div key={index} className='col-lg-6'>
                                            <img className='w-100' src={item.image} alt={`article ${index}`} />
                                            <p className='category'>{item.category}</p>
                                            <p className='heading'>{item.heading}</p>
                                            <p className='text'>{item.text}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReadOurArticle;