import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllRoutes from "./Pages/Routes/AllRoutes";
import "./assests/css/Style.css";
import AOS from "aos";
import { useSelector, useDispatch } from "react-redux";
import { AboutApi, HomeApi } from "./store/action/HomeAction";
import { BlogApi, BlogCategoriesApi } from "./store/action/BlogAction";
import { GiverApi } from "./store/action/GiverAction";
import { TokensApi } from "./store/action/cryptotokenAction";
import { SiteSettingApi } from "./store/action/SiteSettingAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(HomeApi());
		dispatch(AboutApi());
		dispatch(BlogApi());
		dispatch(GiverApi());
		dispatch(TokensApi());
		dispatch(SiteSettingApi());
	}, []);
	return (
		<>
			<div>
				<ToastContainer />
			</div>
			<div className="App">
				<AllRoutes />
			</div>
		</>
	);
}

export default App;
