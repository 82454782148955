import React from "react";
import { useSelector } from "react-redux";
import Benefit_img from "../../assests/images/Partnership/Benefits.png";
const BenefitsPartnership2 = (props) => {
	const { partnerData } = useSelector((state) => state.partner);
	return (
		<div className="BenefitsPartnership ">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 mb-rr text-center">
						<p className="main_head ">Benefits Of being part of Benefacto</p>
					</div>
					{partnerData?.data?.benefits?.data?.map((item, index) => {
						return (
							<div key={index} className="col-lg-4">
								<div className="gry_div">
									<img className="imggg mb-3" src={Benefit_img} alt="dollar" />
									<p className="txt mb-4">{item?.description}</p>
									<p className="heading_brdr">{item?.title}</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default BenefitsPartnership2;
