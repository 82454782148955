import { createSlice } from "@reduxjs/toolkit";
import { SaveSpaceApi, SaveSpaceCateApi } from "../action/SavespaceAction";

const SaveSpaceReducer = createSlice({
	name: "SaveSpaceReducer",
	initialState: {
		SaveSpaceData: [],
		SaveSpaceCate: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[SaveSpaceApi.pending]: (state) => {
			state.loading = true;
		},
		[SaveSpaceApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.SaveSpaceData = action.payload.response;
		},
		[SaveSpaceApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[SaveSpaceCateApi.pending]: (state) => {
			state.loading = true;
		},
		[SaveSpaceCateApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.SaveSpaceCate = action.payload.response;
		},
		[SaveSpaceCateApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default SaveSpaceReducer.reducer;
