import { createSlice } from "@reduxjs/toolkit";
import { CummunityApi } from "../action/CummunityAction";

const CummunityReducer = createSlice({
	name: "CummunityApi",
	initialState: {
		cummunityData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[CummunityApi.pending]: (state) => {
			state.loading = true;
		},
		[CummunityApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.cummunityData = action.payload.response;
		},
		[CummunityApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default CummunityReducer.reducer;
