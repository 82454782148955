import React, { useState, useLayoutEffect, useEffect } from "react";
import "../../assests/css/OurApproach.css";
import approach_1 from "../../assests/images/home/approach_1.png";
import approach_2 from "../../assests/images/home/approach_2.png";
import $ from "jquery";
import gsap from "gsap";
import { useSelector } from "react-redux";

const OurApproach = () => {
	const { homeData } = useSelector((state) => state.Home);
	const [trans, settrans] = useState(200);
	const [trans2, settrans2] = useState(0);
	const [sliderimg, setsliderimg] = useState(approach_2);
	const [sliderimg2, setsliderimg2] = useState(approach_1);
	$(window).scroll(function () {
		console.log(window.location.hash);
		if (window.location.hash == "#/" || window.location.hash == "") {
			var scroll = $(document).scrollTop();
			// console.log("this this this",scroll);
			// $(document).ready(function(){
			//   $('.OurApproach').sc
			// })
			document.querySelector(".OurApproach").addEventListener("scroll", step);
			function step() {
				let offset = 10;
				let maxScroll = (offset * window.innerHeight) / 100;
				let newTranslate = Math.tan(maxScroll);
				let maxScroll2 = (offset + 1 * window.innerHeight) / 200;
				let newTranslate2 = Math.tan(maxScroll2);
				settrans(newTranslate);
				settrans2(newTranslate2);
				setsliderimg(approach_1);
				setsliderimg2(approach_2);
			}
			// if (scroll > 5800 && scroll < 6200) {
			// } else {
			//   settrans(0);
			//   setsliderimg(approach_2);
			//   settrans2(150);
			//   setsliderimg2(approach_1);
			// }
			// if (scroll > 3200 && scroll < 4200) {
			//   let maxScroll = 3000;
			//   let newS = maxScroll - scroll;
			//   let percentage = (newS / maxScroll) * 100;
			//   let newTranslate = 100 * (percentage / 100);
			// } else {
			// }
			if ($(".OurApproach").hasClass("darkHeader")) {
				$(".io").addClass("all");
				$(".io2").addClass("all2");
			}
			if (scroll > 5800) {
				$(".OurApproach").addClass("darkHeader");
				console.log("Approch" + scroll);
			} else {
				$(".OurApproach").removeClass("darkHeader");
			}
		}
	});
	let Image = homeData?.data?.giver?.image_url;
	return (
		<div className="OurApproach">
			<div className="approch-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 mb-5">
							<p className="main_page_heading">Our Approach</p>
							{/* <div className='brdr-btm-line mb-3'>

                        </div> */}
							<p className="text">
								Et leo duis ut diam quam nulla. Ullamcorper velit sed
								ullamcorper morbi tincidunt ornare massa. Scelerisque felis
								imperdiet proin fermentum leo vel orci.
							</p>
						</div>
						<div className="col-lg-6">
							<p className="sub_heading ">Givers</p>
							{/* <div className='brdr-btm-line mb-3'>

                        </div> */}
							{/* <p>
								Luctus accumsan tortor posuere ac ut consequat semper viverra
								nam. Ac auctor augue mauris augue neque. Amet commodo nulla
								facilisi nullam vehicula ipsum a arcu.{" "}
							</p>
							<div className="numbers-div">
								<p>01</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor.
								</p>
							</div>
							<div className="numbers-div">
								<p>02</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor.
								</p>
							</div>
							<div className="numbers-div">
								<p>03</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor.
								</p>
							</div> */}
							<p
								className="blog-des"
								dangerouslySetInnerHTML={{
									__html: homeData?.data?.giver?.description,
								}}
							/>
						</div>
						<div className="col-lg-6">
							<div className="slider-img-wrapper">
								<div className="row">
									<div className="col-lg-6">
										<div className="img-wrapper io">
											<img
												className="img-fluid slide_img-1 "
												src={`${Image}${homeData?.data?.giver?.image}`}
												style={{ transform: `translateY(${trans2}px)` }}
											/>
										</div>

										{/* <img className="img-fluid scroll-1-img" src={approach_2} /> */}
									</div>
									<div className="col-lg-6">
										<div className="img-wrapper io2">
											<img
												className="img-fluid slide_img-2"
												src={`${Image}${homeData?.data?.giver?.image2}`}
												style={{
													transform: `translateY(${trans}px) rorate3d(1,1,1)`,
												}}
											/>
										</div>
										{/* <img className="img-fluid scroll-2-img" src={approach_1} /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurApproach;
