import { AOS } from "aos";
import React, { useEffect } from "react";
import "../../assests/css/OddEvenImagesContent.css";
import { useSelector } from "react-redux";

const OddEvenImagesContent2 = (props) => {
	const { receiverData } = useSelector((item) => item.receiver);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	return (
		<div className="OddEvenImagesContent">
			<div className="container">
				{receiverData?.data?.receivers?.data?.map((item, index) => {
					return (
						<div key={index} className="row">
							{index % 2 != 0 ? (
								<div className="row my-res-odd-even">
									<div className="col-lg-6">
										<div className="img_box">
											<figure>
												<img
													data-aos="fade-right"
													src={`${item.image_url}${item.image}`}
													alt="recevers content"
													className="w-100"
												/>
											</figure>
											<figure>
												<img
													data-aos="fade-right"
													data-aos-offset="300"
													data-aos-easing="ease-in-sine"
													src={`${item.image_url}${item.image2}`}
													alt="recevers content"
													className="w-100"
												/>
											</figure>
										</div>
									</div>
									<div className="col-lg-6 pl-5">
										<p className="heading mb-4">{item.title}</p>
										<p
											className="text"
											dangerouslySetInnerHTML={{
												__html: item?.description,
											}}
										/>
										{item?.items_lists?.map((list_item, i) => {
											return (
												<div
													className="listing_main_div"
													data-aos="flip-down"
													key={i}
												>
													<p className="number">0{i + 1}</p>
													<p className="text">{list_item.text}</p>
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<div className="row my-res-odd-even">
									<div className="col-lg-6 pr-5">
										<p className="heading mb-4">{item?.title}</p>
										<p
											className="text"
											dangerouslySetInnerHTML={{
												__html: item?.description,
											}}
										/>
										{item?.items_lists?.map((list_item, i) => {
											return (
												<div
													className="listing_main_div"
													data-aos="flip-up"
													key={i}
												>
													<p className="number">0{i + 1}</p>
													<p className="text">{list_item.text}</p>
												</div>
											);
										})}
									</div>
									<div className="col-lg-6">
										<div className="img_box">
											<figure>
												<img
													data-aos="fade-right"
													src={`${item.image_url}${item.image}`}
													alt="recevers content"
													className="w-100"
												/>
											</figure>
											<figure>
												<img
													data-aos="fade-right"
													data-aos-offset="500"
													data-aos-easing="ease-in-sine"
													src={`${item.image_url}${item.image2}`}
													alt="recevers content"
													className="w-100"
												/>
											</figure>
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default OddEvenImagesContent2;
