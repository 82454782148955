import { createSlice } from "@reduxjs/toolkit";
import { policyApi } from "../action/TermCondition";

const PolicyReducer = createSlice({
	name: "policyApi",
	initialState: {
		policyApiss: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[policyApi.pending]: (state) => {
			state.loading = true;
		},
		[policyApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.policyApiss = action.payload.response;
		},
		[policyApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default PolicyReducer.reducer;
