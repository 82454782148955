import React, { useEffect } from "react";
import AOS from "aos";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import Footer from "../../Components/Footer/Footer";
import Footer_Four from "../../Components/Footer/Footer_Four";
import WeWouldLike from "../../Components/WeWouldLikeToInvest/WeWouldLike";
import powerplant from "../../assests/images/custom_image/Crypto_footer1.png";
import hands from "../../assests/images/custom_image/Crypto_footer2.png";
import bg_img from "../../assests/images/RightHalfImagetop/cryptoToken.png";
import "../../assests/css/Partnership.css";
import RightHalfImageTop from "../../Components/RightHalfImageTop/RightHalfImageTop";
import Header from "../../Components/Header/Header";
import WhyToGetInvolvedPartnership from "../../Components/WhyToGetInvolvedPartnership/WhyToGetInvolvedPartnership";
import Card_Partnership from "../../Components/Card_Partnership/Card_Partnership";
import img1 from "../../assests/images/Crypto_Token/img1.webp";
import img2 from "../../assests/images/Crypto_Token/img1.png";
import Benefit_img from "../../assests/images/Partnership/Benefits.png";
import BenefitsPartnership from "../../Components/BenefitsPartnership/BenefitsPartnership";
import RoadMap from "../../Components/RoadMap_CryptoToken/RoadMap";
import { useSelector } from "react-redux";

const CryptoToken = () => {
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	let Card_Content = [
		{
			image: img1,
			heading: "We do good for the wild Animal",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. Facilisis gravida neque convallis a cras semper auctor neque. Eu feugiat pretium nibh ipsum consequat nisl. ",
		},
		{
			image: img2,
			heading: "We do For Student to get higher education",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. Facilisis gravida neque convallis a cras semper auctor neque. Eu feugiat pretium nibh ipsum consequat nisl. ",
		},
	];

	let MultiContent_One = {
		image: powerplant,
		heading_one: "The best place for you",
		heading_two: "To invest for Good",
	};
	let MultiContent_Two = {
		image: hands,
		heading_one: "Buy Our coin for",
		heading_two: "Double Profit",
	};

	let bg_top_sec = {
		bg_image: bg_img,
		heading_one: "Introducing",
		heading_two: "Crypto Token",
		// heading_three: "Better Future",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
	};

	let Partnership = {
		// left_text: "Invest in Good",
		// right_txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",
	};

	let Benefits = [
		{
			image: Benefit_img,
			heading: "Benefits",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
		{
			image: Benefit_img,
			heading: "Benefits",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
		{
			image: Benefit_img,
			heading: "Benefits",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
	];

	return (
		<div className="Partnership">
			<Header black={true} />
			<section className="top_sec pos-rel GetStartedTopSection">
				<RightHalfImageTop content={bg_top_sec} />
			</section>
			<section className="whygetinvovled">
				<WhyToGetInvolvedPartnership content={Partnership} />
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<p data-aos="fade-right" className="benefacto_coin">
								Benefacto coin
							</p>
							<p data-aos="fade-left" className="benefacto_coin_p">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="whygetinvovled">
				<Card_Partnership content={Card_Content} />
			</section>
			<section className="BenefitsPartnership">
				<BenefitsPartnership content={Benefits} />
			</section>
			<section className="Road_map_to">
				<RoadMap />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_One} />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_Two} />
			</section>
			<section className="last_section">
				<Footer />
			</section>
		</div>
	);
};

export default CryptoToken;
