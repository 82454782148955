import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import FirstComponent from '../../Components/Home/FirstComponent';
import SecondComponent from '../../Components/Home/SecondComponent';
import ThirdComponent from '../../Components/Home/ThirdComponent';
import FourthComponent from '../../Components/Home/FourthComponent';
import ReactPageScroller from 'react-page-scroller';
import '../../assests/css/Home.css';
import '../../assests/css/Common.css'
import Header from '../../Components/Header/Header';
import FifthComponent from '../../Components/Home/FifthComponent';
import SixthComponent from '../../Components/Home/SixthComponent';
import CustomIamgeSection from '../../Components/CustomIamgeSection/CustomIamgeSection';
import hands from '../../assests/images/custom_image/hands.webp';
import Footer_Four from '../../Components/Footer/Footer_Four';
import Footer from '../../Components/Footer/Footer';
import SingleImageCompoenent from '../../Components/SingleImage/SingleImageCompoenent';
import Single_image_one from '../../assests/images/single_image/bg-fish.png';
import TwelfthComponent from '../../Components/Home/TwelfthComponent';
import TenthComponent from '../../Components/Home/TenthComponent';
import bg_space_video from '../../assests/video/intro_space.mp4';
import bg_green_video from '../../assests/video/intro_video.mp4';
import CustomVideoSection from '../../Components/CustomVideoSection/CustomVideoSection';
import SeventComponent from '../../Components/Home/SeventComponent';
import VideoBgPausePlay from '../../Components/VideoBgPausePlay/VideoBgPausePlay';
import help_enviroment from '../../assests/images/home/tenth_component.png';
import OurServices from '../../Components/Home/OurServices';
import OurApproach from '../../Components/Home/OurApproach';
import Home1 from '../../Components/HomeDuplicate/Home1';
import Home2 from '../../Components/HomeDuplicate/Home2';
import ReadOurArticle from '../../Components/HomeDuplicate/ReadOurArticle';

const HomeDuplicate = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [currentPage, setState] = useState(null);

    const handlePageChange = number => {
        setState(number);
    };
    const handleBeforePageChange = number => {
        // console.log(number);
    };
    useEffect(() => {
        setTimeout(() => {
            AOS.init();
        }, 1000);
    }, [])

    let MultiContent_One = { image: hands, heading_one: "We Motivate to do", heading_two: "Social Good" }

    let singleImageConenet = {
        image: Single_image_one
    }

    let bgVideo_Space = {
        video: bg_space_video,
    }

    let bgVideo_Forest = {
        video: bg_green_video,
    }

    let we_help_to_save_enviroment = {
        image: help_enviroment,
        heading_one: "We help to",
        heading_two: "Save Enviroment",
    }

    let we_motivate_to_do_good = {
        image: hands,
        heading_one: "We Motivate to do",
        heading_two: "Social Good",
    }

    return (
        <div className='home_page'>
            <Header />
            {/* <Home1 /> */}
            {/* <Home2/> */}
            <ReadOurArticle />
        </div>
    );
};

export default HomeDuplicate;