import { createSlice } from "@reduxjs/toolkit";
import { AboutApi, HomeApi } from "../action/HomeAction";

const Homereducers = createSlice({
	name: "HomeApi",
	initialState: {
		homeData: [],
		aboutData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[HomeApi.pending]: (state) => {
			state.loading = true;
		},
		[HomeApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.homeData = action.payload.response;
		},
		[HomeApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
		[AboutApi.pending]: (state) => {
			state.loading = true;
		},
		[AboutApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.aboutData = action.payload.response;
		},
		[AboutApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default Homereducers.reducer;
