import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const SiteSettingApi = createAsyncThunk(
	"site-settings/detail",
	async () => {
		try {
			const { data } = await axios.get(`${URL}site-settings/get-site-settings`);
			return data;
		} catch (err) {
			return err.message;
		}
	},
);
