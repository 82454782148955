import React from "react";
import Slider from "react-slick/lib/slider";
import "../../assests/css/Sliders.css";
import LifeBelowWater1 from "../../assests/images/life_below_water/slider_1.webp";
import LifeBelowWater2 from "../../assests/images/life_below_water/slider_2.webp";
import LifeBelowWater3 from "../../assests/images/life_below_water/slider_3.webp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";

const AroundTheGlobeSlider = () => {
	let navigate = useNavigate();

	function SampleNextArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className="Slider_top_custom_arrows-next"
				// style={{ ...style, display: "block", background: "red", height: "80px", width: "80px" }}
				onClick={onClick}
			/>
		);
	}

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className="Slider_top_custom_arrows"
				// style={{ ...style, display: "block", background: "green", height: "80px", width: "80px" }}
				onClick={onClick}
			/>
		);
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		// appendDots: dots => (
		//     <div
		//         style={{
		//             backgroundColor: "black",
		//             borderRadius: "10px",
		//             color: "white",
		//             padding: "10px"
		//         }}
		//     >
		//         <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
		//     </div>
		// ),
	};

	let aroundSlider = [
		{
			image: LifeBelowWater1,
			heading: "At auctor urna nunc id cursus metus aliquam",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
		},
		{
			image: LifeBelowWater2,
			heading: "At auctor urna nunc id cursus metus aliquam",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
		},
		{
			image: LifeBelowWater3,
			heading: "At auctor urna nunc id cursus metus aliquam",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
		},
		{
			image: LifeBelowWater1,
			heading: "At auctor urna nunc id cursus metus aliquam",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
		},
		{
			image: LifeBelowWater2,
			heading: "At auctor urna nunc id cursus metus aliquam",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
		},
		{
			image: LifeBelowWater3,
			heading: "At auctor urna nunc id cursus metus aliquam",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
		},
	];

	return (
		<div className="AroundTheGlobeSlider">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 mb-4">
						<h1 className="around_globe_heading">Around the Globe</h1>
					</div>
					<div className="col-lg-6 mb-4"></div>
					<div className="col-lg-12">
						<Slider {...settings} className="slider_top_home pos-rel">
							{aroundSlider?.map((item, index) => {
								return (
									<div key={index} className="row">
										<div className="col-lg-12">
											<img
												src={item.image}
												className="w-100"
												alt="slider content"
											/>
											<p className="slider_heading mt-3">{item.heading}</p>
											<p className="slider_p">{item.desc}</p>
											<p
												onClick={() => {
													navigate("/");
												}}
												className="clr_grn pointer_cursor mt-5"
											>
												Read More <ArrowRightAltIcon className=" ml-3" />
											</p>
										</div>
									</div>
								);
							})}
							{/* <div style={{ backgroundImage: `url('${img_laptop}')` }} className='bg-slider-image-top'>

                            </div> */}
						</Slider>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AroundTheGlobeSlider;
