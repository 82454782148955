import React from "react";
import { useSelector } from "react-redux";

const Card_Partnership = (props) => {
	const { TokenData } = useSelector((state) => state.token);
	return (
		<div className="Card_Partnership">
			<div className="container">
				{TokenData?.data?.tokens?.data?.map((item, index) => {
					return (
						<div className="row my-card-r section">
							<div className="col-lg-6 align-center">
								<div className="section__body">
									<div className="section__text">
										<p className="heading mb-4">{item?.title}</p>
										<p className="text">{item?.description}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-6 ">
								<div className="section__img">
									<div
										className="section__img__inner"
										style={{
											backgroundImage: `url(${item?.image_url}${item?.image})`,
										}}
									>
										{/* <img src={item.image} className=" w-100 img-fluid" alt="our item" /> */}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Card_Partnership;
