import { createSlice } from "@reduxjs/toolkit";
import { EarthActionApi } from "../action/EarthAction";

const EarthReducer = createSlice({
	name: "faqsApi",
	initialState: {
		EarthData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[EarthActionApi.pending]: (state) => {
			state.loading = true;
		},
		[EarthActionApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.EarthData = action.payload.response;
		},
		[EarthActionApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default EarthReducer.reducer;
