import React, { useEffect, useState } from "react";
import AOS from "aos";
import Header from "../../Components/Header/Header";
import first_img from "../../assests/images/Review_Community/rec1.png";
import second_img from "../../assests/images/Review_Community/rec2.png";
import "../../assests/css/BlogCategories.css";
import Footer_Four from "../../Components/Footer/Footer_Four";
import Footer from "../../Components/Footer/Footer";
import { bgCate, bpick1, btpick, oceanbg, cate2, cate3 } from "../../constant";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { BlogApi, BlogCategoriesApi } from "../../store/action/BlogAction";

function BlogCategories() {
	const [currentPageState, setcurrentPageState] = useState(1);
	const { blogsData, blogscategoriesData } = useSelector(
		(state) => state.Blogs,
	);
	console.log(blogsData, "bloooooooooooooooooooo");
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	let data = {
	// 		pages: 1,
	// 		cateId: "",
	// 	};
	// 	dispatch(BlogCategoriesApi(data));
	// }, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	let data;
	const HandleUrl = (url) => {
		data = {
			pages: url?.split("?page=")[1],
			cateId: "",
		};
		dispatch(BlogCategoriesApi(data));
	};

	const HandleChangeCate = (id) => {
		data = {
			cateId: id,
			pages: 1,
		};
		dispatch(BlogCategoriesApi(data));
	};

	useEffect(() => {
		HandleChangeCate();
	}, []);

	const bestPickData = [
		{
			id: 1,
			para1:
				" Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet.",
		},
		{
			id: 2,
			para1:
				" Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet.",
		},
		{
			id: 3,
			para1:
				" Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet.",
		},
		{
			id: 4,
			para1:
				" Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet.",
		},
	];

	const blosData = [
		{
			id: 1,
			para1:
				"Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
		},
		{
			id: 2,
			para1:
				"Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
		},
		{
			id: 3,
			para1:
				"Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
		},
	];

	const blosData2 = [
		{
			id: 1,
			para1: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		},
		{
			id: 2,
			para1: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		},
		{
			id: 3,
			para1: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		},
		{
			id: 4,
			para1: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		},
		{
			id: 5,
			para1: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		},
		{
			id: 6,
			para1: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
			para2:
				"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
		},
	];

	const tabsData = [
		{
			id: 1,
			para1: "We do For Student to get higher education",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
			img: bpick1,
		},
		{
			id: 2,
			para1: "We do For Student to get higher education",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
			img: cate2,
		},
		{
			id: 3,
			para1: "We do For Student to get higher education",
			para2:
				"Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in.",
			img: cate3,
		},
	];
	return (
		<>
			<div className="blogCate-main">
				<Header black={true} />
				<div className="blogCate-inner">
					<div className="row">
						<div className="col-md-6">
							<img src={bgCate} alt="" className="image-fluid image-width" />
						</div>
						<div className="col-md-6">
							<div className="banner-content">
								<p data-aos="fade-left" className="mainTitle">
									Be a Part of
								</p>
								<p data-aos="fade-right" className="subTitle">
									Benefacto team
								</p>
								<h3 data-aos="fade-left" className="subTitle2">
									To do Good
								</h3>
								<p data-aos="fade-right" className="mainPara">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
								</p>

								<div data-aos="fade-left" className="input-container mt-3">
									<input type="text" placeholder="Search Blogs" />
									<div data-aos="fade-right" className="btn-container">
										<button className="join">Search</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="tabs-container">
							<div className="tabs-stuff">
								<ul
									data-aos="flip-down"
									className="nav nav-tabs"
									role="tablist"
								>
									<li className="nav-item">
										<a
											className="nav-link active"
											data-toggle="tab"
											href="#tabs-1"
											role="tab"
											onClick={() => HandleChangeCate()}
										>
											All Category
										</a>
									</li>
									{blogsData?.data?.categories?.map((item, index) => {
										return (
											<li className="nav-item" key={item.id}>
												<a
													className="nav-link"
													data-toggle="tab"
													href="#tabs-2"
													role="tab"
													onClick={() => HandleChangeCate(item.id)}
												>
													{item?.title}
												</a>
											</li>
										);
									})}
								</ul>
								{/* <!-- Tab panes --> */}
								<div className="tab-content">
									<div className="tab-pane active" id="tabs-1" role="tabpanel">
										<div className="row tab-contMain">
											{blogscategoriesData?.data?.blogs?.data?.map(
												(item, index) => (
													<div className="col-md-4" key={index + 10}>
														<div data-aos="flip-down" className="tabs-card">
															<div className="imgBox">
																<img
																	src={`${item?.image_url}${item?.image}`}
																	alt=""
																	className="image-fluid image-width"
																/>
															</div>
															<div>
																<p className="mainTitle">{item?.title}</p>

																<p
																	className="mainPara"
																	dangerouslySetInnerHTML={{
																		__html: item?.description,
																	}}
																/>
																<Link to="/" className="mt-2">
																	Read More
																	<ArrowRightAltIcon className="ml-2" />
																</Link>
															</div>
														</div>
													</div>
												),
											)}
										</div>
									</div>
									<div className="tab-pane" id="tabs-2" role="tabpanel">
										<div
											className="tab-pane active"
											id="tabs-1"
											role="tabpanel"
										>
											<div className="row tab-contMain">
												{blogscategoriesData?.data?.blogs?.data?.map(
													(item, index) => (
														<div className="col-md-4" key={index + 10}>
															<div data-aos="flip-down" className="tabs-card">
																<div className="imgBox">
																	<img
																		src={`${item?.image_url}${item?.image}`}
																		alt=""
																		className="image-fluid image-width"
																	/>
																</div>
																<div>
																	<p className="mainTitle">{item?.title}</p>

																	<p
																		className="mainPara"
																		dangerouslySetInnerHTML={{
																			__html: item?.description,
																		}}
																	/>
																	<Link to="/" className="mt-2">
																		Read More
																		<ArrowRightAltIcon className="ml-2" />
																	</Link>
																</div>
															</div>
														</div>
													),
												)}
											</div>
										</div>
									</div>
									<div className="tab-pane" id="tabs-3" role="tabpanel">
										<div
											className="tab-pane active"
											id="tabs-1"
											role="tabpanel"
										>
											<div className="row tab-contMain">
												{blogscategoriesData?.data?.blogs?.data?.map(
													(item, index) => (
														<div className="col-md-4" key={index + 10}>
															<div data-aos="flip-down" className="tabs-card">
																<div className="imgBox">
																	<img
																		src={`${item?.image_url}${item?.image}`}
																		alt=""
																		className="image-fluid image-width"
																	/>
																</div>
																<div>
																	<p className="mainTitle">{item?.title}</p>

																	<p
																		className="mainPara"
																		dangerouslySetInnerHTML={{
																			__html: item?.description,
																		}}
																	/>
																	<Link to="/" className="mt-2">
																		Read More
																		<ArrowRightAltIcon className="ml-2" />
																	</Link>
																</div>
															</div>
														</div>
													),
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex blog_links_page">
									{blogscategoriesData?.data?.blogs?.links?.length > 3
										? blogscategoriesData?.data?.blogs?.links?.map(
												(item, index) => {
													if (item?.label === "&laquo; Previous") {
														return (
															<p onClick={() => HandleUrl(item?.url)}>
																{item?.label.slice(7)}
															</p>
														);
													} else if (item?.label === "Next &raquo;") {
														return (
															<p onClick={() => HandleUrl(item?.url)}>
																{item?.label.slice(0, 4)}
															</p>
														);
													} else {
														return (
															<p
																className={`${
																	blogscategoriesData?.data?.blogs
																		?.current_page == item?.label
																		? "activesss"
																		: ""
																}`}
																onClick={() => HandleUrl(item?.url)}
															>
																{item?.label}
															</p>
														);
													}
												},
										  )
										: null}
								</div>
							</div>
						</div>
						<div className="bestPick">
							<div className="head">
								<p data-aos="fade-left">Life below water</p>
								<Link data-aos="fade-right" to="">
									View All
								</Link>
							</div>
							<div className="row">
								<div className="col-md-7">
									<img
										data-aos="flip-down"
										src={btpick}
										alt=""
										className="image-fluid image-width"
									/>
								</div>
								<div data-aos="flip-up" className="col-md-5 right-Container">
									<p className="title">Some Best Picks</p>
									<div className="row">
										{blogsData?.data?.blogs?.data?.map((item, index) => {
											return index < 4 ? (
												<>
													<div className="col-md-8 mt-2 mb-2" key={index}>
														<p data-aos="fade-left" className="mainTitle">
															{item?.title}
														</p>
														<p
															data-aos="fade-right"
															className="mainPara"
															dangerouslySetInnerHTML={{
																__html: item?.description,
															}}
														/>
													</div>
													<div className="col-md-4 mt-2 mb-2">
														<img
															data-aos="flip-down"
															src={`${item?.image_url}${item?.image}`}
															alt=""
															className="image-fluid image-width"
														/>
													</div>
												</>
											) : null;
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="started">
							<div className="row">
								<div className="col-md-6 offSet-6">
									<p data-aos="fade-left" className="subTitle">
										Life below water
									</p>
									<h3 data-aos="fade-right" className="mainTitle">
										How Benefacto Started heling people for Good
									</h3>
									<p data-aos="fade-left" className="para">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Duis aute irure dolor.
									</p>
									<Link data-aos="fade-right" to="">
										Read More
										<ArrowRightAltIcon className="ml-2" />
									</Link>
								</div>
							</div>
						</div>
						<div className="blogs">
							<div className="row">
								<div className="col-md-7 offSet-5">
									<div className="row">
										{blogsData?.data?.blogs?.data?.map((item, index) => {
											return index < 3 ? (
												<>
													<div className="col-md-4 mt-3 mb-4" key={index + 30}>
														<img
															src={`${item?.image_url}${item?.image}`}
															alt=""
															className="image-fluid image-width"
														/>
													</div>
													<div className="col-md-8 mt-3 mb-4" key={index}>
														<p data-aos="fade-left" className="para">
															Space Sustainibility
														</p>
														<p data-aos="fade-right" className="mainTitle">
															{item?.title}
														</p>

														<p
															data-aos="fade-left"
															className="mainPara"
															dangerouslySetInnerHTML={{
																__html: item?.description,
															}}
														/>
													</div>
												</>
											) : null;
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="ocean-sec">
							<div className="row">
								<div
									data-aos="fade-right"
									data-aos-offset="300"
									data-aos-easing="ease-in-sine"
									className="col-md-8"
								>
									<div className="ocean-inner">
										<p className="sm-p">Life below Ocean</p>
										<h3 className="mainTitle">
											Sagittis purus sit amet volutpat. Pulvinar neque laoreet
											suspendisse interdum consectetur libero id.
										</h3>
										<p className="para">
											Egestas integer eget aliquet nibh praesent tristique magna
											sit amet. Leo vel fringilla est ullamcorper eget nulla
											facilisi. Dui ut ornare lectus sit amet est placerat in.
											Tellus at urna condimentum mattis.
										</p>
										<div className="btn-container">
											<button className="btn read-btn">
												Read More
												<ArrowRightAltIcon className="ml-2" />
											</button>
										</div>
									</div>
								</div>
								<div
									data-aos="fade-left"
									data-aos-offset="300"
									data-aos-easing="ease-in-sine"
									className="col-md-4"
								>
									<div className="imgBox">
										<img
											src={oceanbg}
											alt=""
											className="image-fluid image-width"
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-left"
							data-aos-offset="300"
							data-aos-easing="ease-in-sine"
							className="blog-vt"
						>
							<div className="row">
								{blogsData?.data?.blogs?.data?.map((item, index) => {
									return (
										<div key={index + 100} className="col-md-2">
											<div>
												<div className="imgBox">
													<img
														src={`${item?.image_url}${item?.image}`}
														alt=""
														className="image-fluid image-width"
													/>
												</div>
												<div>
													<p className="sm-p">Space Sustainibility</p>
													<p className="mainTitle">{item?.title}</p>
													<p
														className="mainPara"
														dangerouslySetInnerHTML={{
															__html: item?.description,
														}}
													/>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>

				<Footer_Four />
				<Footer />
			</div>
		</>
	);
}

export default BlogCategories;
