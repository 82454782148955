import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const ContactApi = createAsyncThunk("contact/detail", async (datass) => {
	try {
		const { data } = await axios.post(`${URL}contactStore`, datass);
		if (data?.message == "data retrieved successfully") {
			toast.success(`Your Information Submit Successfully`);
		}
		return data.message;
	} catch (err) {
		console.log(err);
		if (err?.message == "Request failed with status code 400") {
			toast.error(`Your Email formate is not valid`);
		}
		return err.message;
	}
});
