import React, { useEffect } from 'react';
import AOS from 'aos';
import CustomIamgeSection from '../../Components/CustomIamgeSection/CustomIamgeSection';
import Footer from '../../Components/Footer/Footer';
import Footer_Four from '../../Components/Footer/Footer_Four';
import WeWouldLike from '../../Components/WeWouldLikeToInvest/WeWouldLike';
import powerplant from '../../assests/images/custom_image/LegalLink.png';
import hands from '../../assests/images/custom_image/hands.webp';
import bg_img from '../../assests/images/RightHalfImagetop/bg_img_top2.webp';
import '../../assests/css/Partnership.css';
import RightHalfImageTop from '../../Components/RightHalfImageTop/RightHalfImageTop';
import Header from '../../Components/Header/Header';
import WhyToGetInvolvedPartnership from '../../Components/WhyToGetInvolvedPartnership/WhyToGetInvolvedPartnership';
import Card_Partnership from '../../Components/Card_Partnership/Card_Partnership';
import img1 from "../../assests/images/Partnership/pg2_img1.png";
import img2 from "../../assests/images/Partnership/pg2_img2.png";
import img3 from "../../assests/images/Partnership/pg2_img3.png";
import img4 from "../../assests/images/Partnership/pg2_img4.webp";
import Benefit_img from '../../assests/images/Partnership/Benefits.png';
import BenefitsPartnership from '../../Components/BenefitsPartnership/BenefitsPartnership';


const LegalLink = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        setTimeout(() => {
          AOS.init();
        }, 1000);
      }, [])
    let Card_Content = [
        {
            image: img1,
            heading: "We do good for life below the ocean",
            desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. Facilisis gravida neque convallis a cras semper auctor neque. Eu feugiat pretium nibh ipsum consequat nisl. "
        }, {
            image: img2,
            heading: "We do For Student to get higher education",
            desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. Facilisis gravida neque convallis a cras semper auctor neque. Eu feugiat pretium nibh ipsum consequat nisl. "
        }, {
            image: img3,
            heading: "We Eliminate hunger To spread Happiness",
            desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. Facilisis gravida neque convallis a cras semper auctor neque. Eu feugiat pretium nibh ipsum consequat nisl. "
        },
        {
            image: img4,
            heading: "We are Responsible consumption and production",
            desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. Facilisis gravida neque convallis a cras semper auctor neque. Eu feugiat pretium nibh ipsum consequat nisl. "
        },

    ]

    let MultiContent_One = { image: powerplant, heading_one: "The best place for you", heading_two: "To invest for Good" }
    let MultiContent_Two = { image: hands, heading_one: "We Motivate to do", heading_two: "Social Good" }

    let bg_top_sec = {
        bg_image: bg_img,
        heading_one: "The best Place",
        heading_two: "for you to Invest",
        heading_three: "Better Future",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    }

    let Partnership = {
        mainHeading: "Why get Involved",
        left_text: "Invest in Good",
        right_txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.",

    }

    let Benefits = [
        {
            image: Benefit_img,
            heading: "Benefits",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. "
        }, {
            image: Benefit_img,
            heading: "Benefits",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. "
        }, {
            image: Benefit_img,
            heading: "Benefits",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. "
        },
    ]



    return (
        <div className='Partnership'>
            <Header black={true} />
            <section className='top_sec pos-rel'>
                <RightHalfImageTop content={bg_top_sec} />
            </section>
            <section className='whygetinvovled'>
                <WhyToGetInvolvedPartnership content={Partnership} />
            </section>
            <section className='whygetinvovled'>
                <Card_Partnership content={Card_Content} />
            </section>
            <section className='BenefitsPartnership'>
                <BenefitsPartnership content={Benefits} />
            </section>
            <section className='custom_images_section'>
                <CustomIamgeSection content={MultiContent_One} />
            </section>
            <section className='custom_images_section'>
                <CustomIamgeSection content={MultiContent_Two} />
            </section>
            <section className='wewouldlike'>
                <WeWouldLike />
            </section>
            <section className='Footer_Four_section'>
                <Footer_Four />
            </section>
            <section className='last_section'>
                <Footer />
            </section>
        </div>
    )
}

export default LegalLink;