import { createSlice } from "@reduxjs/toolkit";
import { SubscribeApi } from "../action/Subscibe";

const SubscribeReducer = createSlice({
	name: "SubscribeReducer",
	initialState: {
		message: "",
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[SubscribeApi.pending]: (state) => {
			state.loading = true;
		},
		[SubscribeApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.message = action.payload;
		},
		[SubscribeApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default SubscribeReducer.reducer;
