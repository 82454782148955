import React, { useEffect, useState } from "react";
import AOS from "aos";
import Header from "../../Components/Header/Header";
import first_img from "../../assests/images/Review_Community/rec1.png";
import second_img from "../../assests/images/Review_Community/rec2.png";
import {
	cmob1,
	cmob2,
	cmob3,
	logoIcon,
	mob1,
	mob2,
	mob3,
} from "../../constant";
import "../../assests/css/SpaceSustainibility.css";
import Footer_Four from "../../Components/Footer/Footer_Four";
import Footer from "../../Components/Footer/Footer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { SubscribeApi } from "../../store/action/Subscibe";
import { EarthActionApi } from "../../store/action/EarthAction";

function SpaceSustainibility() {
	const [subscribe, setSubscribe] = useState("");
	const alignCenter = { display: "flex", alignItems: "center" };
	useEffect(() => {
		dispatch(EarthActionApi());
	}, []);
	const { EarthData } = useSelector((item) => item.earth);
	console.log(EarthData?.data?.earths?.data, "dsdsdsdsdsds");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	const dispatch = useDispatch();
	const HandleSub = () => {
		console.log("dsdsds");
		const formData = new FormData();
		formData.append("email", subscribe);
		dispatch(SubscribeApi(formData));
	};

	const { message } = useSelector((item) => item.subscibe);
	console.log(message, "meeeeeeeeeeeeeee");

	const cardData = [
		{
			id: 1,
			img: cmob1,
			para1:
				"Euismod quis viverra nibh Cras pulvinar mattis nunc sed Viverra suspendisse potenti",
			para2:
				" Sapien et ligula ullamcorper malesuada proin. Egestas dui id ornare arcu odio ut sem nulla pharetra. Etiam sit amet nisl purus in mollis nunc. Eget gravida cum sociis natoque. Pharetra convallis posuere morbi leo urna molestie at. Morbi tempus iaculis urna id volutpat lacus laoreet non.",
		},
		{
			id: 2,
			img: cmob2,
			para1:
				"Non pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus",
			para2:
				" Sapien et ligula ullamcorper malesuada proin. Egestas dui id ornare arcu odio ut sem nulla pharetra. Etiam sit amet nisl purus in mollis nunc. Eget gravida cum sociis natoque. Pharetra convallis posuere morbi leo urna molestie at. Morbi tempus iaculis urna id volutpat lacus laoreet non.",
		},
		{
			id: 3,
			img: cmob3,
			para1:
				"At volutpat diam ut venenatis Pretium aenean pharetra magna ac placerat vestibulum",
			para2:
				" Sapien et ligula ullamcorper malesuada proin. Egestas dui id ornare arcu odio ut sem nulla pharetra. Etiam sit amet nisl purus in mollis nunc. Eget gravida cum sociis natoque. Pharetra convallis posuere morbi leo urna molestie at. Morbi tempus iaculis urna id volutpat lacus laoreet non.",
		},
	];
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
		$(window)
			.scroll(function () {
				// selectors
				var $window = $(window),
					$body = $("body"),
					$panel = $(".section");

				// Change 33% earlier than scroll position so colour is there when you arrive.
				var scroll = $window.scrollTop() + $window.height() / 3;

				$(".section").removeClass("active");

				$panel.each(function () {
					var $this = $(this);

					// if position is within range of this panel.
					// So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
					// Remember we set the scroll to 33% earlier in scroll var.
					if (
						$this.position().top <= scroll &&
						$this.position().top + $this.height() > scroll
					) {
						$this.addClass("active");
					}
				});
			})
			.scroll();
	}, []);
	return (
		<>
			<div className="spaceSustainibility-main">
				<Header black={true} />
				<div className="spaceSustainibility-inner">
					<div className="container">
						<div className="row">
							<div className="col-md-5 .banner-left-sec">
								<h3 className="main-title">Benefacto</h3>
								<h4 className="sec-title">{EarthData?.data?.section?.title}</h4>
								<p className="main-para">
									{EarthData?.data?.section?.description}
								</p>
							</div>
							<div className="col-md-7">
								<div className="row">
									<div className="col-md-6">
										<img
											src={`${EarthData?.data?.section?.image_url}${EarthData?.data?.section?.image1}`}
											alt=""
											className="image-fluid image-width"
										/>
									</div>
									<div className="col-md-6 banner-right-sec">
										<img
											src={`${EarthData?.data?.section?.image_url}${EarthData?.data?.section?.image2}`}
											alt=""
											className="image-fluid image-width"
										/>
									</div>
								</div>
							</div>
							<div className="col-md-5"></div>
							<div className="col-md-7">
								<div className="row">
									<div className="col-md-6 mob-3">
										<img
											src={`${EarthData?.data?.section?.image_url}${EarthData?.data?.section?.image3}`}
											alt=""
											className="image-fluid image-width"
										/>
									</div>
									<div className="col-md-6"></div>
								</div>
							</div>
						</div>
						<div className="our-mission">
							<p className="mission-p1">Join Our mission to help other</p>
							<p className="mission-p2">to do good</p>
							<p className="mission-p3">
								Luctus accumsan tortor posuere consequat semper viverra.
							</p>
							<div className="mt-3">
								<input
									type="text"
									placeholder="Write Your Email"
									value={subscribe}
									onChange={(e) => setSubscribe(e.target.value)}
								/>
								<div className="btn-container">
									<button
										className="join"
										style={{ zIndex: "9999" }}
										onClick={HandleSub}
									>
										Submit <ArrowRightAltIcon className="ml-2" />
									</button>
								</div>
							</div>
						</div>
						<div className="card-mob">
							<div className="container">
								{EarthData?.data?.earths?.data?.map((data, index) => {
									return (
										<div className="row cmb-main section">
											<div className="col-lg-6">
												<div className="section__body">
													<div className="section__text">
														<p className="cmb-card-p1">{data?.title}</p>
														<p className="cmb-card-p2">{data?.description}</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="section__img">
													<div
														className="section__img__inner"
														style={{
															backgroundImage: `url(${data?.image_url}${data?.image})`,
														}}
													></div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>

						<div className="sm-card milestone_cards">
							<div className="row section">
								<div className="col-md-6 content_body section__img">
									<div
										className="section__img__inner"
										style={{ left: "10%", top: "10%" }}
									>
										<p className="para1">{EarthData?.data?.section.title2}</p>
										<p className="para2">
											{EarthData?.data?.section.description2}
										</p>
									</div>
								</div>
								<div className="col-md-6 neg section__body">
									<div className="row section__text">
										<div className="col-md-6 mb-4">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number1}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text1}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 pos">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number2}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text2}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 neg">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number3}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text3}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 pos">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number4}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text4}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 neg">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number5}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text5}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 pos">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number6}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text6}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 neg">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number7}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text7}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
										<div className="col-md-6 mb-4 pos">
											<div className="right-tags">
												<p className="cardTitle">
													{EarthData?.data?.section.number8}
												</p>
												<p className="cardPara">
													{EarthData?.data?.section.text8}
												</p>
												<div>
													<img
														src={logoIcon}
														alt=""
														className="image-fluid mt-5"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="opp-email">
					<div className="container">
						<p className="mainTitle">
							Benefacto offers Opportunity be the first to avail
						</p>
						<p className="subTitle">Enter Your email to join Wait list</p>
						<div className="mt-3">
							<input
								type="text"
								placeholder="Write Your Email"
								value={subscribe}
								onChange={(e) => setSubscribe(e.target.value)}
							/>
							<div className="btn-container">
								<button
									className="join"
									style={{ zIndex: "9999" }}
									onClick={HandleSub}
								>
									Join waitlist <ArrowRightAltIcon className="ml-2" />
								</button>
							</div>
						</div>
					</div>
				</div>
				<Footer_Four />
				<Footer />
			</div>
		</>
	);
}

export default SpaceSustainibility;
