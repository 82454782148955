import React, { useRef } from "react";
import "../../assests/css/Cards_Section.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import RemoveIcon from "@mui/icons-material/Remove";

import card_img1 from "../../assests/images/savenature/img1.png";
import card_img2 from "../../assests/images/savenature/img2.png";
import card_img3 from "../../assests/images/savenature/img3.png";
import card_img4 from "../../assests/images/savenature/img4.png";
import card_img5 from "../../assests/images/savenature/img5.png";
import card_img6 from "../../assests/images/savenature/img6.png";
import card_img7 from "../../assests/images/savenature/img7.png";
import card_img8 from "../../assests/images/savenature/img8.png";
import card_img9 from "../../assests/images/savenature/img9.png";
import card_img10 from "../../assests/images/savenature/img10.png";
import card_img11 from "../../assests/images/savenature/img11.png";
import card_img12 from "../../assests/images/savenature/img12.png";
import card_img13 from "../../assests/images/savenature/img13.png";
import card_img14 from "../../assests/images/savenature/img14.png";
import { useSelector } from "react-redux";

const Cards_Section = () => {
	const { SavenatureData } = useSelector((item) => item.saveNature);
	console.log(SavenatureData, "sasassdasda");
	let myRef = useRef([]);

	let arr = [
		{
			id: 1,
			image: card_img1,
			heading: "NO POVERTY",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 2,
			image: card_img2,
			heading: "ZERO HUNGER",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 3,
			image: card_img3,
			heading: "GOOD HEALTH AND WELL BEING",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 4,
			image: card_img4,
			heading: "QUALITY EDUCATION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 5,
			image: card_img5,
			heading: "GENDER EQUALITY",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 6,
			image: card_img6,
			heading: "CLEAN WATER AND SANITATION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 7,
			image: card_img7,
			heading: "AFFORDABLE AND CLEAN ENERGY",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 8,
			image: card_img8,
			heading: "DECENT WORK ECONOMIC GROWTH",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 9,
			image: card_img9,
			heading: "INDUSTRY, INNOVATION AND INFRASTRUCTURE",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 10,
			image: card_img10,
			heading: "SUSTAINABLE CITIES AND COMMUNITIES",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 11,
			image: card_img11,
			heading: "RESPONSIBLE CONSUMPTION AND PRODUCTION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 12,
			image: card_img12,
			heading: "CLIMATE ACTION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 13,
			image: card_img13,
			heading: "LIFE BELOW WATER",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 14,
			image: card_img14,
			heading: "LIFE ON LAND",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
	];

	const handleOnClick = (event) => {
		myRef[event].scrollIntoView(0);
	};

	return (
		<div className="cards_section">
			<div className="container">
				<div className="row">
					<div className="col-lg-2 pos-rel">
						<div className="fix_pagination">
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(1);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">01-02</span>
							</div>
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(3);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">03-04</span>
							</div>
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(5);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">05-06</span>
							</div>
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(7);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">07-08</span>
							</div>
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(9);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">09-10</span>
							</div>
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(11);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">11-12</span>
							</div>
							<div
								className="mt-4 pointer_cursor"
								onClick={() => {
									handleOnClick(13);
								}}
							>
								<RemoveIcon />
								<span className="ml-4">13-14</span>
							</div>
						</div>
					</div>
					<div className="col-lg-10">
						<div className="row">
							{SavenatureData?.data?.natures?.data.map((item, index) => {
								return (
									<div
										ref={(ref) => {
											myRef[index] = ref;
										}}
										id={item.id}
										key={index}
										className={`${
											index % 2 === 0 ? "col-lg-6 " : "col-lg-6 mt-card"
										}`}
									>
										<div
											style={{
												backgroundImage: `url(${item.image_url}${item.image})`,
											}}
											className="crd-bg-img pos-rel"
										>
											<div className="hovr_cards_content">
												<p className="card_heading">{item.title}</p>
												<p className="card_desc">{item.description}</p>
											</div>
										</div>
										<div className="brdr-btm-gry">
											<p className="card_bottom_heading">{item.title}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cards_Section;
