import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const PartnerApi = createAsyncThunk("partner/detail", async () => {
	try {
		const { data } = await axios.get(`${URL}partners/get-partners`);
		return data;
	} catch (err) {
		return err.message;
	}
});
