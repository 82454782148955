import { createSlice } from "@reduxjs/toolkit";
import { GiverApi } from "../action/GiverAction";

const GiverReducer = createSlice({
	name: "GiverApi",
	initialState: {
		giverData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[GiverApi.pending]: (state) => {
			state.loading = true;
		},
		[GiverApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.giverData = action.payload.response;
		},
		[GiverApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default GiverReducer.reducer;
