import React, { useEffect } from "react";
import AOS from "aos";
import "../../assests/css/LifeBelowWater.css";
import howstarted_top from "../../assests/images/how_started/howstarted_top.webp";
import { blog_1, blog_2, blog_3, blog_4, blog_5 } from "../../constant/index";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import AroundTheGlobeSlider from "../../Components/Sliders/AroundTheGlobeSlider";
import JoinCommunity from "../../Components/JoinCommunity/JoinCommunity";
import { useSelector } from "react-redux";

const Blog_section = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	let navigate = useNavigate();
	const { homeData } = useSelector((state) => state.Home);
	console.log(homeData);

	let top_content = [
		{
			image: blog_2,
			catergory: "#Business",
			heading: "Startup Tips",
			desc: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam. ",
		},
		{
			image: blog_3,
			catergory: "#Business",
			heading: "Startup Tips",
			desc: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam.",
		},
		{
			image: blog_4,
			catergory: "#Business",
			heading: "Startup Tips",
			desc: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam.",
		},
		{
			image: blog_5,
			catergory: "#Business",
			heading: "Startup Tips",
			desc: "Enim ut tellus elementum sagittis. Aliquet nec ullamcorper sit amet risus nullam.",
		},
	];

	//   let bottom_content = [
	//     {
	//       image: content_img_group,
	//       catergory: "Space Sustainibility",
	//       heading:
	//         "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
	//       desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
	//     },
	//     {
	//       image: content_img_group,
	//       catergory: "Space Sustainibility",
	//       heading:
	//         "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
	//       desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
	//     },
	//     {
	//       image: content_img_group,
	//       catergory: "Space Sustainibility",
	//       heading:
	//         "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
	//       desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
	//     },
	//     {
	//       image: content_img_group,
	//       catergory: "Space Sustainibility",
	//       heading:
	//         "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
	//       desc: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehend in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
	//     },
	//   ];

	return (
		<div className="home-blog LifeBelowWater">
			<div className="LifeBelowWater_content">
				<section className="top_section">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<h2>Read Our Articles</h2>
								<h3>
									Be Notified About <br /> Everything
								</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<marquee
								scrollamount="5"
								direction="right"
								width="100%"
								behavior="alternate"
							>
								<ul>
									<li>We Care</li>
									<li>We motivate </li>
									<li>We Care</li>
									<li>We Aspire</li>
									<li>We Inspire</li>
									<li>We Care</li>
									<li>We Aspire</li>
									<li>We Inspire</li>
								</ul>
							</marquee>
						</div>
					</div>
					<div className="container">
						<div className="row ">
							<div className="col-lg-6 my-5">
								<img className="w-100" src={blog_1} alt="how started" />
								<div className="text-center">
									<p className="life_before_txt">#Ecosystem</p>

									<p className="top_heading_px">How to protect Ecosystem</p>
									<p className="text_main">
										Gravida quis blandit turpis cursus in hac habitasse platea
										dictumst. Quisque sagittis purus sit amet volutpat consequat
										congue.
									</p>
								</div>
							</div>
							<div className="col-lg-6 my-5">
								<div className="row blog-wrapper mb-5">
									{homeData?.data?.blogs?.data.map((item, index) => {
										return (
											<div key={index} className="col-lg-6">
												<div className="card-wrapper">
													<div className="card-img">
														<img
															src={`${item?.image_url}${item?.image}`}
															className="img_grp_ppl"
															alt="group people"
														/>
													</div>
													<div className="card-content-wrapper">
														<p className="text-top-group">{item?.catergory}</p>
														<p className="text-top-group-heading">
															{item?.title}
														</p>
														<p
															className="text-top-group-desc"
															dangerouslySetInnerHTML={{
																__html: item?.description,
															}}
														/>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Blog_section;
