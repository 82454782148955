import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const SubscribeApi = createAsyncThunk(
	"subscribe/detail",
	async (email) => {
		try {
			const { data } = await axios.post(`${URL}newsLetterStore`, email);
			if (data?.message == "data retrieved successfully") {
				toast.success("Subscibe Successfully");
			}
			return data.message;
		} catch (err) {
			toast.error(err?.response?.data?.errors?.email[0]);
			return err.message;
		}
	},
);
