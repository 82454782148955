import { createSlice } from "@reduxjs/toolkit";
import { PartnerApi } from "../action/PartnerAction";

const PartnerReducer = createSlice({
	name: "PartnerApi",
	initialState: {
		partnerData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[PartnerApi.pending]: (state) => {
			state.loading = true;
		},
		[PartnerApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.partnerData = action.payload.response;
		},
		[PartnerApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default PartnerReducer.reducer;
