import React from 'react';
import image1 from '../../assests/images/WhygetInvolved/image1.png';
import image2 from '../../assests/images/WhygetInvolved/image1.png';
import image3 from '../../assests/images/WhygetInvolved/image1.png';
import image4 from '../../assests/images/WhygetInvolved/image1.png';


const WhyToGetInvolvedPartnership = (props) => {

    let arr = [
        {
            image: image1,
            heading: "Eliminate Poverty from the world",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam.  Ac auctor augue mauris augue.",
        },
        {
            image: image2,
            heading: "Eliminate Poverty from the world",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam.  Ac auctor augue mauris augue.",
        },
        {
            image: image3,
            heading: "Eliminate Poverty from the world",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam.  Ac auctor augue mauris augue.",
        },
        {
            image: image4,
            heading: "Eliminate Poverty from the world",
            desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam.  Ac auctor augue mauris augue.",
        }
    ]

    return (
        <div className='WhyToGetInvolvedPartnership'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <p className='main_heading'>{props?.content?.mainHeading}</p>
                    </div>
                    {arr.map((item, index) => {
                        return (
                            <div key={index} className='col-lg-3'>
                                <img className='img_item mb-4' src={item.image} />
                                <p className='heading mb-3'>{item.heading}</p>
                                <p className='txt'>{item.desc}</p>
                            </div>
                        )
                    })}

                    <div className='col-lg-6 my-6rem'>
                        <p className='main_heading_brdr'>{props.content.left_text}</p>
                    </div>
                    <div className='col-lg-6 my-6rem'>
                        <p className='txt'>{props.content.right_txt}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyToGetInvolvedPartnership;