import React from "react";
import { useSelector } from "react-redux";
import person from "../../assests/images/getInvolved/Person.png";

const OurCommunity_Person = () => {
	const { cummunityData } = useSelector((item) => item.cummunity);
	return (
		<div className="WhyToGetInvolved">
			<div className="container">
				<div className="row">
					<div className="col-lg-5">
						<p data-aos="fade-right" className="heading">
							Our Community of
							<br />
							benefacto
						</p>
						<p data-aos="fade-left" className="text mt-4">
							Quam vulputate dignissim suspendisse in est ante in. Pellentesque
							massa placerat duis ultricies lacus sed turpis tincidunt. Risus
							nec feugiat in fermentum posuere urna nec tincidunt praesent.
							Vitae nunc sed velit dignissim sodales ut eu sem. Magna fermentum
							iaculis eu non diam phasellus vestibulum lorem. Elit sed vulputate
							mi sit. Phasellus faucibus scelerisque eleifend donec pretium.
							Nunc lobortis mattis aliquam faucibus purus in massa. Lacus sed
							viverra tellus in hac habitasse platea dictumst. Vitae auctor eu
							augue ut lectus arcu bibendum at varius.
						</p>
					</div>
					<div className="col-lg-7">
						<div className="row">
							{cummunityData?.data?.benefits?.data?.map((item, index) => {
								return (
									<>
										<div className="col-lg-6">
											<div data-aos="flip-up" className="gry_box mb-4">
												<div className="row">
													<div className="col-lg-9">
														<img src={person} alt="person get involved" />
													</div>
													<div className="col-lg-3">
														<p className="number">
															{index > 10 ? index : `0${index + 1}`}
														</p>
													</div>
													<div className="col-lg-12 mt-5 mb-2">
														<p className="benefits">{item?.title}</p>
														<p className="text mb-0">{item?.description}</p>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurCommunity_Person;
