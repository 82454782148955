import React from "react";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
	const { policyApiss } = useSelector((item) => item.policy);
	return (
		<div className="tc_content">
			<p
				className="blog-des"
				dangerouslySetInnerHTML={{
					__html: policyApiss.data.data[0].content,
				}}
			/>
		</div>
	);
};

export default PrivacyPolicy;
