import React, { useEffect, useState } from "react";
import AOS from "aos";
import "../../assests/css/TermsAndCondition.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import PrivacyPolicy from "../../Components/T&C/PrivacyPolicy";
import TermsContents from "../../Components/T&C/TermsContents";
import TermsOfUse from "../../Components/T&C/TermsOfUse";
import { useDispatch, useSelector } from "react-redux";
import { policyApi } from "../../store/action/TermCondition";

const TermsAndCondition = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(policyApi());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [currentTab, setCurrentTab] = useState("terms&condition");

	const ChangeTabs = (e) => {
		setCurrentTab(e);
	};
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);

	return (
		<div className="TermsAndCondition">
			<Header black={true} />
			<div className="TermsAndCondition_content">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h1 className="policy_heading">Legal Links</h1>
						</div>
						<div className="col-lg-12 mb-5">
							<div className="tabs">
								<button
									onClick={() => {
										ChangeTabs("terms&condition");
									}}
									className={
										currentTab == "terms&condition"
											? "btn btn-light bt-actv-tc"
											: "btn btn-light bt-tc"
									}
								>
									Terms & Condition
								</button>

								<button
									onClick={() => {
										ChangeTabs("privacypolicy");
									}}
									className={
										currentTab == "privacypolicy"
											? "btn btn-light bt-actv-tc"
											: "btn btn-light bt-tc"
									}
								>
									Privacy Policy
								</button>

								<button
									onClick={() => {
										ChangeTabs("termsofuse");
									}}
									className={
										currentTab == "termsofuse"
											? "btn btn-light bt-actv-tc"
											: "btn btn-light bt-tc"
									}
								>
									Terms of Use
								</button>
							</div>
						</div>
						<div className="col-lg-12">
							{currentTab === "terms&condition" && <TermsContents />}
							{currentTab === "privacypolicy" && <PrivacyPolicy />}
							{currentTab === "termsofuse" && <TermsOfUse />}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default TermsAndCondition;
