import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const RightHalfImageTop = (props) => {
    return (
        <div className='RightHalfImageTop'>
            <div className='flx-main'>
                <div className='flx-item-left'>
                    <div className="lft-pd">
                        <div className="row ">
                            <div className="col-lg-12">
                                <h1 className="heading_light">{props.content.heading_one}</h1>
                                <h1 className="heading">{props.content.heading_two}</h1>
                                <h1 className="heading">{props.content.heading_three}</h1>
                                <p className="text my-4">{props.content.text}</p>
                                <button className="btn btn-light bt-get-started">
                                    Get Started <ArrowRightAltIcon className='ml-2' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flx-item-right'>
                    <div style={{ backgroundImage: `url("${props.content.bg_image}")` }} className="GetStartedTopSection mb-getStarted">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightHalfImageTop;