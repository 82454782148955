import React from 'react';

const TwelfthComponent = () => {
    return (
        <div className='TwelfthComponent'>
            <div className='green_div'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className='heading'>Protect Eco system</p>
                            <p className='text'>Every factor in an ecosystem depends on </p>
                            <p className='text'>every other factor, either directly or indirectly. A change in the </p>
                            <p className='text'>temperature of an ecosystem will often affect what plants will grow</p>
                            <p className='text'>there</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwelfthComponent;