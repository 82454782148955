import React, { useEffect } from "react";
import AOS from "aos";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import Footer from "../../Components/Footer/Footer";
import Footer_Four from "../../Components/Footer/Footer_Four";
import powerplant from "../../assests/images/custom_image/AboutUs.png";
import bg_img from "../../assests/images/RightHalfImagetop/left_half.webp";
import "../../assests/css/AboutUs.css";
import Header from "../../Components/Header/Header";
import LeftImageTop from "../../Components/LeftTopImage_AboutUs/LeftImageTop";
import WhatWeAreAbout from "../../Components/WhatWeAreAbout_AboutUs/WhatWeAreAbout";

const AboutUs = () => {
	let MultiContent_One = {
		image: powerplant,
		heading_one: "Commodo quis imperdiet",
		heading_two: "massa tincidunt",
	};

	let bg_top_sec = {
		bg_image: bg_img,
		heading_one: "Duis aute irure dolor in repre",
		heading_two: "henderit in voluptate velit.",
		text_one:
			"Ipsum consequat nisl vel pretium lectus. Velit ut tortor pretium viverra suspendisse potenti nullam. Id venenatis a condimentum vitae sapien. Convallis tellus id interdum velit laoreet. Vel facilisis volutpat est velit egestas dui. Quisque egestas diam in arcu cursus euismod quis viverra. Tortor id aliquet lectus proin. Felis donec et odio pellentesque diam volutpat commodo.",
		text_two:
			"Enim praesent elementum facilisis leo vel fringilla est ullamcorper eget. Parturient montes nascetur ridiculus mus mauris. Id aliquet lectus proin nibh nisl condimentum id. Sagittis id consectetur purus ut faucibus pulvinar elementum. ",
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	return (
		<div className="AboutUs">
			<Header black={true} />
			<section className="top_sec pos-rel">
				<LeftImageTop content={bg_top_sec} />
			</section>
			<section className="WhatWeAreAbout_section">
				<WhatWeAreAbout />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_One} />
			</section>
			<section className="Footer_Four_section">
				<Footer_Four />
			</section>
			<section className="last_section">
				<Footer />
			</section>
		</div>
	);
};

export default AboutUs;
