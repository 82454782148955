import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let URL = "https://benefacto-backend.developer-ourbase-camp.com/api/";

export const SaveSpaceApi = createAsyncThunk(
	"savespace/detail",
	async (typeId) => {
		try {
			const { data } = await axios.get(
				`${URL}spaces/get-space-type?type=${typeId ? typeId : 1}`,
			);
			return data;
		} catch (err) {
			return err.message;
		}
	},
);

export const SaveSpaceCateApi = createAsyncThunk(
	"savespacecate/detail",
	async () => {
		try {
			const { data } = await axios.get(`${URL}spaces/get-spaces`);
			return data;
		} catch (err) {
			return err.message;
		}
	},
);
