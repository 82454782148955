import React, { useRef, useEffect } from "react";
import home_firstvideo from "../../assests/video/intro_video.mp4";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const FirstComponent = () => {
	// if (typeof window !== "undefined") {
	//   gsap.registerPlugin(ScrollTrigger);
	// }
	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		let sections = gsap.utils.toArray(".pul");

		gsap.to(sections, {
			xPercent: -100 * (sections.length - 1),
			ease: "none",
			scrollTrigger: {
				trigger: ".home_page_first_section",
				pin: true,
				scrub: 1,
				snap: 1 / (sections.length - 1),
				// base vertical scrolling on how wide the container is so it feels more natural.
				end: "+=3500",
			},
		});
	});
	let navigate = useNavigate();
	return (
		<div className="home_page_first_section pos-rel">
			<div className="first_component">
				<video id="video" className="videoTag" autoPlay loop muted>
					<source src={home_firstvideo} type="video/mp4" />
				</video>
				<div className="pul">
					<div className="doll">
						<div className="container">
							<div className="bg-dty"></div>
							<div className="row">
								<div className="col-lg-6 pos-rel">
									<div className="left_home_div">
										<div className="home_left_text">
											<div className="pad-top-text">
												{/* <p className="home_content_large">
                      We <span className="clr_grn ml-3">Care</span>
                    </p>
                    <p className="home_content_small">to do Good</p> */}
												<div className="head-wrapper">
													<span className="we">We</span>
													<h1 className="ml4">
														<span className="letters letters-1">Care</span>
														<span className="letters letters-2">Aspire</span>
														<span className="letters letters-3">Inspire</span>
														<span className="letters letters-4">Motivate</span>
													</h1>
												</div>
												<span className="good">to do Good</span>
												<p
													onClick={() => {
														navigate("/savenature");
													}}
													className="text-white pointer_cursor mt-4"
												>
													Learn More <ArrowRightAltIcon className=" ml-3" />
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="home_right_text"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pul"></div>
			</div>
		</div>
	);
};

export default FirstComponent;
