import React from "react";
import { useSelector } from "react-redux";

const RoadMap = () => {
	const { TokenData } = useSelector((state) => state.token);
	console.log(TokenData, "sdaaaaaa");
	return (
		<div className="RoadMap">
			<div className="container">
				<div className="row">
					<div className="offset-lg-2 col-lg-8 text-center">
						<p className="road_map_head">Road map to Benefacto Tokens </p>
						<p className="road_map_p">
							Egestas integer eget aliquet nibh praesent tristique magna sit
							amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui
							ut ornare lectus sit amet est placerat in. Tellus at urna
							condimentum mattis.{" "}
						</p>
						{TokenData?.data?.maps?.data.map((item, index) => {
							return (
								<div className="card_roadmap">
									<div className="row">
										<>
											<div className="col-lg-3">
												<p className="numbr">
													{index > 10 ? index : `0${index + 1}`}
												</p>
											</div>
											<div className="col-lg-9">
												<p className="heading">Odio aenean sed adipiscing</p>
												<p className="txt mb-3">
													Egestas integer eget aliquet nibh praesent tristique
													magna sit amet. Leo vel fringilla est ullamcorper eget
													nulla facilisi. Dui ut ornare lectus sit amet est
													placerat in. Tellus at urna condimentum mattis.{" "}
												</p>
												<ul className="ul_pad_zero">
													<li className="ul_tick_round_white">
														Egestas integer eget aliquet nibh praesent tristique
														magna sit amet.{" "}
													</li>
													<li className="ul_tick_round_white">
														Ut sem nulla pharetra diam sit amet nisl suscipit
													</li>
													<li className="ul_tick_round_white">
														At auctor urna nunc id cursus metus aliquam eleifend
														mi
													</li>
													<li className="ul_tick_round_white">
														{" "}
														Libero enim sed faucibus turpis in eu mi bibendum
														neque
													</li>
												</ul>
											</div>
										</>
									</div>
								</div>
							);
						})}
						{/* <div className="card_roadmap">
                            <div className="row">
                                <div className="col-lg-3">
                                    <p className="numbr">02</p>
                                </div>
                                <div className="col-lg-9">
                                    <p className="heading">Odio aenean sed adipiscing</p>
                                    <p className="txt mb-3">Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. </p>
                                    <ul className="ul_pad_zero">
                                        <li className="ul_tick_round_white">Egestas integer eget aliquet nibh praesent tristique magna sit amet. </li>
                                        <li className="ul_tick_round_white">Ut sem nulla pharetra diam sit amet nisl suscipit</li>
                                        <li className="ul_tick_round_white">At auctor urna nunc id cursus metus aliquam eleifend mi</li>
                                        <li className="ul_tick_round_white"> Libero enim sed faucibus turpis in eu mi bibendum neque</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card_roadmap">
                            <div className="row">
                                <div className="col-lg-3">
                                    <p className="numbr">03</p>
                                </div>
                                <div className="col-lg-9">
                                    <p className="heading">Odio aenean sed adipiscing</p>
                                    <p className="txt mb-3">Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. </p>
                                    <ul className="ul_pad_zero">
                                        <li className="ul_tick_round_white">Egestas integer eget aliquet nibh praesent tristique magna sit amet. </li>
                                        <li className="ul_tick_round_white">Ut sem nulla pharetra diam sit amet nisl suscipit</li>
                                        <li className="ul_tick_round_white">At auctor urna nunc id cursus metus aliquam eleifend mi</li>
                                        <li className="ul_tick_round_white"> Libero enim sed faucibus turpis in eu mi bibendum neque</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card_roadmap">
                            <div className="row">
                                <div className="col-lg-3">
                                    <p className="numbr">04</p>
                                </div>
                                <div className="col-lg-9">
                                    <p className="heading">Odio aenean sed adipiscing</p>
                                    <p className="txt mb-3">Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. </p>
                                    <ul className="ul_pad_zero">
                                        <li className="ul_tick_round_white">Egestas integer eget aliquet nibh praesent tristique magna sit amet. </li>
                                        <li className="ul_tick_round_white">Ut sem nulla pharetra diam sit amet nisl suscipit</li>
                                        <li className="ul_tick_round_white">At auctor urna nunc id cursus metus aliquam eleifend mi</li>
                                        <li className="ul_tick_round_white"> Libero enim sed faucibus turpis in eu mi bibendum neque</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
					</div>
					<div className="col-lg-12 mb-5 text-center">
						<button className="btn btn-light bt-grn-roadmap">
							Read our investor deck
						</button>
						<button className="btn btn-light bt-grn-roadmap">
							Read our technical roadmap
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RoadMap;
