import { createSlice } from "@reduxjs/toolkit";
import { SiteSettingApi } from "../action/SiteSettingAction";

const SiteSettingReducer = createSlice({
	name: "SitesettingReducer",
	initialState: {
		SitesettingData: [],
		loading: false,
		errors: {},
	},
	reducers: {},
	extraReducers: {
		[SiteSettingApi.pending]: (state) => {
			state.loading = true;
		},
		[SiteSettingApi.fulfilled]: (state, action) => {
			state.loading = false;
			state.SitesettingData = action.payload.response;
		},
		[SiteSettingApi.rejected]: (state, action) => {
			state.loading = false;
			state.errors = action.payload;
		},
	},
});

export default SiteSettingReducer.reducer;
